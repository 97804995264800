<!--用户资产  -->
<template>
  <div>
    <el-button type="success"
               @click="dialogTableVisible = true">资产变更</el-button>
    <template>
      <el-dialog title="资产变更"
                 :visible.sync="dialogTableVisible">
        <el-form ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="变更类型">
            <el-select v-model="alterationType"
                       placeholder="请选择资产类型">
              <el-option label="增加"
                         value=1></el-option>
              <el-option label="减少"
                         value=0></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="积分">
            <el-input v-model="form.score"
                      style="width:300px"></el-input>
          </el-form-item>

          <el-form-item label="轻子">
            <el-input v-model="form.lepton"
                      style="width:300px"></el-input>
          </el-form-item>

          <el-form-item label="夸克">
            <el-input v-model="form.quark"
                      style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea"
                      v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit">提交</el-button>
            <el-button @click="dialogTableVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
    <el-card style="margin-top:10px">
      <h2 style="margin:10px 0px">父节点列表</h2>
      <el-table :data="parentRecommendResList"
                border
                style="width: 100%">
        <el-table-column prop="phone"
                         label="手机号">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click.native="gotoUserInfo(scope.row.phone)">{{scope.row.phone}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="userName"
                         label="姓名">
        </el-table-column>
        <el-table-column prop="levelEng"
                         label="层级">
        </el-table-column>
        <el-table-column prop="score"
                         label="积分">
        </el-table-column>
        <el-table-column prop="lepton"
                         label="轻子">
        </el-table-column>
        <el-table-column prop="quark"
                         label="夸克">
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top:10px">
      <h2 style="margin:10px 0px">推荐列表</h2>
      <el-table :data="childRecommendResList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="userId"
                border
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="phone"
                         label="手机号">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click.native="gotoUserInfo(scope.row.phone)">{{scope.row.phone}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="userName"
                         label="姓名">
        </el-table-column>
        <el-table-column prop="levelEng"
                         label="层级">
        </el-table-column>
        <el-table-column prop="score"
                         label="积分">
        </el-table-column>
        <el-table-column prop="lepton"
                         label="轻子">
        </el-table-column>
        <el-table-column prop="quark"
                         label="夸克">
        </el-table-column>

      </el-table>
    </el-card>
    <el-card style="margin-top:10px">
      <h2 style="margin:10px 0px">推荐积分记录</h2>
      <el-table :data="assetsAddRecordList"
                border
                style="width: 100%">
        <el-table-column prop="recommenderPhone"
                         label="推荐人手机号">
        </el-table-column>
        <el-table-column prop="recommender"
                         label="推荐人">
        </el-table-column>
        <el-table-column prop="recommendedPhone"
                         label="被推荐人手机号">
        </el-table-column>
        <el-table-column prop="recommended"
                         label="被推荐人">
        </el-table-column>
        <el-table-column prop="levelEng"
                         label="层级">
        </el-table-column>
        <el-table-column prop="score"
                         label="积分">
          <template slot-scope="scope">
            <span v-if="scope.row.score">+{{scope.row.score}}</span>
            <span v-else>{{scope.row.score}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lepton"
                         label="轻子">
          <template slot-scope="scope">
            <span v-if="scope.row.lepton">+{{scope.row.lepton}}</span>
            <span v-else>{{scope.row.lepton}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quark"
                         label="夸克">
          <template slot-scope="scope">
            <span v-if="scope.row.quark">+{{scope.row.quark}}</span>
            <span v-else>{{scope.row.quark}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime"
                         label="创建时间"
                         width="180px">
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top:10px">
      <h2 style="margin:10px 0px">积分变更记录</h2>
      <el-table :data="assetsChangeRecordList"
                border
                style="width: 100%">
        <el-table-column prop="score"
                         label="积分">
          <template slot-scope="scope">
            <span v-if="scope.row.score>0">+{{scope.row.score}}</span>
            <span v-else>{{scope.row.score}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lepton"
                         label="轻子">
          <template slot-scope="scope">
            <span v-if="scope.row.lepton>0">+{{scope.row.lepton}}</span>
            <span v-else>{{scope.row.lepton}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quark"
                         label="夸克">
          <template slot-scope="scope">
            <span v-if="scope.row.quark>0">+{{scope.row.quark}}</span>
            <span v-else>{{scope.row.quark}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks"
                         label="备注">
        </el-table-column>
        <el-table-column prop="createTime"
                         label="创建时间"
                         width="180px">
        </el-table-column>
      </el-table>

    </el-card>
  </div>
</template>

<script>
export default {
  name: 'RecommendTable',
  props: {
    phone: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  watch: {
    phone() {
      this.getRecommendInfo()
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      form: {
        phone: '',
        score: 0,
        lepton: 0,
        quark: 0,
        remarks: ''
      },
      alterationType: '',
      parentRecommendResList: [],
      assetsChangeRecordList: [],
      assetsAddRecordList: [],
      childRecommendResList: []
    }
  },
  methods: {
    async onSubmit() {
      if (this.alterationType == '') {
        this.$message.error('请选择变更类型')
        return
      }
      if (this.alterationType == 0) {
        this.form.score = this.form.score * -1
        this.form.lepton = this.form.lepton * -1
        this.form.quark = this.form.quark * -1
      }
      const { data: res } = await this.$http.post('/admin/assets/changeUserAssets', this.form)
      if (res.code == 200) {
        this.$message.success('变更资产成功')
        this.getRecommendInfo()
      } else {
        this.$message.error(res.msg)
      }
      this.dialogTableVisible = false
      this.form.score = 0
      this.form.lepton = 0
      this.form.quark = 0
      this.form.remarks = ''
    },
    async getRecommendInfo() {
      const { data: res } = await this.$http.get(`/admin/assets/getRecommendInfo?phone=${this.phone}`)
      this.parentRecommendResList = res.data.parentRecommendResList
      this.assetsChangeRecordList = res.data.assetsChangeRecordList
      this.assetsAddRecordList = res.data.assetsAddRecordList
      this.childRecommendResList = res.data.childRecommendResList
    },
    gotoUserInfo(phone) {
      this.$router.push({ path: '/redirect', query: { router: `/userInfo?phone=${phone}&type=${this.type}&tab=3` } })
    }
  },

  mounted() {
    this.form.phone = this.phone
    this.getRecommendInfo()
  }
}
</script>
<style lang='scss' scoped>
</style>