<!--管理员信息  -->
<template>
  <div>
    <el-card class="box-card">
      <div slot="header"
           class="clearfix">
        <span>个人信息</span>
      </div>
      <div class="text item">用户名:{{ userInfo.username }}</div>
      <div class="text item">手机号:{{ userInfo.phone }}</div>
      <div class="text item">邮箱:{{ userInfo.email }}</div>
      <div class="text item">
        头像:<br />
        <img v-if="userInfo.avatar"
             class="img"
             :src="$url+userInfo.avatar"
             width="100px"
             height="100px"
             alt="头像" />
      </div>
      <template>
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">
          <el-col :span="1.5">
            <el-button @click="addDialogVisible = true"
                       type="primary"
                       plain
                       size="mini"
                       icon="el-icon-s-tools">修改密码</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button @click="updAdminInfo"
                       type="success"
                       plain
                       size="mini"
                       icon="el-icon-user">修改信息</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger"
                       @click="altervia = true"
                       plain
                       size="mini"
                       icon="el-icon-upload">修改头像</el-button>
          </el-col>
        </el-row>
      </template>
    </el-card>
    <el-card class="box-card"
             v-if="authAttr">
      <div slot="header"
           class="clearfix">
        <span>对账单配置</span>
      </div>
      <div class="text item">
        <div class="mailbox">发送邮箱:</div>
        <div class="tag"
             v-for="item in receiverEmailList"
             :key="item.value">
          <el-tag>
            {{item.text}}
          </el-tag>
        </div>
      </div>
      <div class="text item">
        <div class="mailbox">抄送邮箱:</div>
        <div class="tag"
             v-for="item in copyEmailList"
             :key="item.value">
          <el-tag>
            {{item.text}}
          </el-tag>
        </div>

      </div>
      <div class="text item">发送频率:每周{{ rebateBillConfigList.dayOfWeek }}-{{ rebateBillConfigList.timeHour}}点</div>
      <template v-if="authAttr>1">
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">
          <el-col :span="1.5">
            <el-button @click="modifyStatement"
                       type="primary"
                       plain
                       size="mini"
                       icon="el-icon-edit">修改对账单配置</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button @click="sendingStatementDialogVisible=true"
                       type="success"
                       plain
                       size="mini"
                       icon="el-icon-check">立即发送账单</el-button>
          </el-col>

        </el-row>
      </template>
    </el-card>
    <!-- 修改对账单配置 -->
    <template>
      <el-dialog title="修改对账单"
                 :visible.sync="ModifyStatementDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifyStatementParameter"
                   ref="modifyStatementParameter"
                   :rules="rules1"
                   label-width="100px">
            <el-form-item label="发送邮箱"
                          prop="receiverEmailList">
              <el-select style="width:100%"
                         v-model="modifyStatementParameter.receiverEmailList"
                         filterable
                         multiple
                         allow-create
                         default-first-option
                         placeholder="请选择或添加发送邮箱">
                <el-option v-for="item in receiverEmailList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抄送邮箱"
                          prop="copyEmailList">
              <el-select style="width:100%"
                         v-model="modifyStatementParameter.copyEmailList"
                         filterable
                         multiple
                         allow-create
                         default-first-option
                         placeholder="请选择或添加发送邮箱">
                <el-option v-for="item in copyEmailList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送周期"
                          prop="dayOfWeek">
              <el-select v-model="modifyStatementParameter.dayOfWeek"
                         placeholder="请选择发送周期">
                <el-option v-for="item in dayOfWeekList"
                           :key="item.value"
                           :label=item.label
                           :value=item.value></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送时间"
                          prop="timeHour">
              <el-select v-model="modifyStatementParameter.timeHour"
                         placeholder="请选择发送时间">
                <el-option v-for="item in timeHourList"
                           :key="item.value"
                           :label=item.label
                           :value=item.value></el-option>

              </el-select>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="ModifyStatementDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitStatement('modifyStatementParameter')">确 定</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 立即发送对账单 -->
    <template>
      <el-dialog title="立即发送对账单"
                 :visible.sync="sendingStatementDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="sendingStatementParameter"
                   ref="sendingStatementParameter"
                   :rules="rules2"
                   label-width="100px">

            <el-form-item prop="startTime"
                          label="开始日期">
              <el-date-picker type="date"
                              placeholder="选择开始日期"
                              v-model="sendingStatementParameter.startTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime"
                          label="结束日期">
              <el-date-picker type="date"
                              placeholder="选择结束日期"
                              v-model="sendingStatementParameter.endTime"
                              style="width: 100%;"></el-date-picker>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="sendingStatementDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="sendOrderRebateBill('sendingStatementParameter')">确 定</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改密码对话框 -->
    <template>
      <el-dialog title="修改密码"
                 :visible.sync="addDialogVisible"
                 width="500px">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="原密码"
                          prop="oldPassword">
              <el-input v-model="recompose.oldPassword"
                        placeholder="请输入原密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword">
              <el-input v-model="recompose.newPassword"
                        placeholder="请输入新密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword2">
              <el-input v-model="recompose.newPassword2"
                        placeholder="请再次输入新密码"
                        show-password>></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitHandler('recompose')">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改用户名对话框 -->
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="alteruser"
                 width="500px">
        <div class="demo-input-suffix">

          <el-form :model="userdata"
                   ref="userdata"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="用户名"
                          prop="newUsername">
              <el-input v-model="userdata.newUsername"
                        placeholder="请输入新用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="userdata.phone"
                        placeholder="请输入新手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="userdata.email"
                        placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span style="color:coral;margin-left: 15%;">请至少选择一项修改</span></br>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alteruser = false">取 消</el-button>
          <el-button type="primary"
                     @click="submituser('userdata')">提 交</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改头像对话框 -->
    <template>
      <el-dialog title="修改头像"
                 :visible.sync="altervia"
                 width="400px">
        <el-form ref="form"
                 label-width="80px">
          <el-form-item label="头像">
            <el-upload action="/api/admin/admin/modifyAdminInfo"
                       name="avatar"
                       ref="upload"
                       :before-upload="beforeAvatarUpload"
                       :headers="headers"
                       :limit="1"
                       list-type="picture-card"
                       :data="queryParams"
                       :auto-upload="false">
              <i slot="default"
                 class="el-icon-plus"></i>
              <div slot="file"
                   slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail"
                     :src="file.url"
                     alt="" />
              </div>
            </el-upload> <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span><br>
          </el-form-item>
        </el-form>

        <span slot="footer"
              class="dialog-footer">
          <el-button @click="qxUpload()">取 消</el-button>
          <el-button type="primary"
                     @click="submitUpdateAvatar">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //查询管理员传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: 0
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 控制修改用户名
      alteruser: false,
      altervia: false,
      ModifyStatementDialogVisible: false,
      sendingStatementDialogVisible: false,
      receiverEmailList: [],
      copyEmailList: [],
      dayOfWeekList: [
        { label: '周一', value: 1 },
        { label: '周二', value: 2 },
        { label: '周三', value: 3 },
        { label: '周四', value: 4 },
        { label: '周五', value: 5 },
        { label: '周六', value: 6 },
        { label: '周日', value: 7 }
      ],
      timeHourList: [
        { label: '00:00', value: 0 },
        { label: '01:00', value: 1 },
        { label: '02:00', value: 2 },
        { label: '03:00', value: 3 },
        { label: '04:00', value: 4 },
        { label: '05:00', value: 5 },
        { label: '06:00', value: 6 },
        { label: '07:00', value: 7 },
        { label: '08:00', value: 8 },
        { label: '09:00', value: 9 },
        { label: '10:00', value: 10 },
        { label: '11:00', value: 11 },
        { label: '12:00', value: 12 },
        { label: '13:00', value: 13 },
        { label: '14:00', value: 14 },
        { label: '15:00', value: 15 },
        { label: '16:00', value: 16 },
        { label: '17:00', value: 17 },
        { label: '18:00', value: 18 },
        { label: '19:00', value: 19 },
        { label: '20:00', value: 20 },
        { label: '21:00', value: 21 },
        { label: '22:00', value: 22 },
        { label: '23:00', value: 23 }
      ],
      modifyStatementParameter: {
        receiverEmailList: [],
        dayOfWeek: '',
        timeHour: '',
        copyEmailList: []
      },
      sendingStatementParameter: {
        startTime: '',
        endTime: ''
      },
      // 修改用户信息传参
      userdata: {
        newUsername: '',
        phone: '',
        type: '',
        email: ''
      },
      recompose: {
        type: '',
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        newUsername: '',
        avatar: '',
        phone: ''
      },
      // 新增信息
      xzdata: false,
      // 新增传参
      xzsj: {
        username: '',
        password: '',
        identity: ''
      },
      userInfo: {
        // username:"张三"
      },
      imageUrl: '',
      headers: {
        token: sessionStorage.getItem('token')
      },
      // 对账单数组
      rebateBillConfigList: [],
      rules: {
        newUsername: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        phone: [{ min: 11, max: 11, message: '请输入11位的手机号', trigger: 'blur' }],
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      },
      rules1: {
        receiverEmailList: [{ required: true, message: '请选择或添加发送邮箱', trigger: 'change' }],
        copyEmailList: [{ required: true, message: '请选择或添加抄送邮箱', trigger: 'change' }],
        timeHour: [{ required: true, message: '请选择发送时间', trigger: 'change' }],
        dayOfWeek: [{ required: true, message: '请选择发送周期', trigger: 'change' }]
      },
      rules2: {
        endTime: [{ required: true, message: '请选择结束', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择开始日期', trigger: 'change' }]
      },
      authAttr: ''
    }
  },
  mounted() {
    this.getAdminInfo()
    this.getRebateBillConfig()
    this.getAuthAttr()
  },
  methods: {
    // 获取权限
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-14')
      this.authAttr = authAttr
    },
    // 提交账单
    submitStatement(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/rebate_bill/updateRebateBillConfig`, this.modifyStatementParameter)
          if (res.code == 200) {
            this.ModifyStatementDialogVisible = false
            this.getRebateBillConfig()
            this.$message.success('修改对账单配置成功')
          } else {
            this.ModifyStatementDialogVisible = false
            return this.$message.error(res.msg)
          }
        }
      })
    },
    // 修改对账单
    modifyStatement() {
      this.ModifyStatementDialogVisible = true
      this.modifyStatementParameter.dayOfWeek = this.rebateBillConfigList.dayOfWeek
      this.modifyStatementParameter.timeHour = this.rebateBillConfigList.timeHour
    },
    sendOrderRebateBill(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/rebate_bill/sendOrderRebateBill`, this.sendingStatementParameter)
          if (res.code == 200) {
            this.sendingStatementDialogVisible = false
            this.$message.success('发送对账单成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async getRebateBillConfig() {
      this.receiverEmailList = []
      this.copyEmailList = []
      const { data: res } = await this.$http.get(`/admin/rebate_bill/getRebateBillConfig`)
      this.rebateBillConfigList = res.data
      this.modifyStatementParameter.receiverEmailList = res.data.receiverEmailList
      this.modifyStatementParameter.copyEmailList = res.data.copyEmailList
      res.data.receiverEmailList.forEach((item) => {
        var obj = { text: item, value: item }
        this.receiverEmailList.push(obj)
      })
      res.data.copyEmailList.forEach((item) => {
        var obj = { text: item, value: item }
        this.copyEmailList.push(obj)
      })
    },
    updAdminInfo() {
      this.alteruser = true
      this.userdata.phone = this.userInfo.phone
      this.userdata.email = this.userInfo.email
      this.userdata.newUsername = this.userInfo.username
    },
    qxUpload() {
      this.altervia = false
      this.$refs.upload.clearFiles()
    },
    submitUpdateAvatar() {
      this.queryParams.type = 3
      this.$refs.upload.submit()
      this.$refs.upload.clearFiles()
      this.altervia = false
      this.getAdminInfo()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async getAdminInfo() {
      this.recompose.type = 1
      const { data: res } = await this.$http.get(`/admin/admin/getAdminInfo`)
      this.userInfo = res.data
    },
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.recompose.type = 1
          if (this.recompose.newPassword != this.recompose.newPassword2) {
            this.$message.error('两次新密码不同')
            return
          }
          if (this.recompose.newPassword == '') {
            this.$message.error('新密码不为空')
            return
          }
          if (this.recompose.oldPassword == '') {
            this.$message.error('原密码不为空')
            return
          }
          const { data: res } = await this.$http.post(`/admin/admin/modifyAdminInfo?type=${this.recompose.type}&oldPassword=${this.recompose.oldPassword}&newPassword=${this.recompose.newPassword}`)
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) {
            alert('密码修改成功，请重新登录')
            window.sessionStorage.removeItem('role')
            window.sessionStorage.removeItem('token')
            window.sessionStorage.removeItem('currentTagList')
            for (var i = 1; i <= 5; i++) {
              window.sessionStorage.removeItem('auth-' + i)
            }
            window.sessionStorage.removeItem('auth')
            window.sessionStorage.removeItem('tokenStartTime')
            this.$router.push('/')
          }
          this.addDialogVisible = false
        }
      })
    },
    // 修改用户名
    submituser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.userdata.type = 2
          const { data: res } = await this.$http.post(
            `/admin/admin/modifyAdminInfo?type=${this.userdata.type}&newUsername=${this.userdata.newUsername}&phone=${this.userdata.phone == null ? '' : this.userdata.phone}&email=${
              this.userdata.email
            }`
          )
          this.alteruser = false
          this.userdata.newUsername = ''
          this.getAdminInfo()
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) return this.$message.success('修改成功')
        }
      })

      //关闭弹窗
    },
    open4() {
      this.xzdata = true
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)
      const { data: res } = await this.$http.post(`/admin/admin/setAdminStatus?id=${row.id}&status=${row.status}`)
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  margin-left: 10%;
}
.text {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  .mailbox {
    width: 60px;
    margin-top: 5px;
  }
  .tag {
    margin: 0px 0px 5px 10px;
  }
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 45%;
  margin-right: 40px;
  float: left;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
