<!-- 代理管理 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>

      <el-form-item label="状态"
                    label-width="68px">
        <el-select v-model="queryParams.status"
                   placeholder="">
          <el-option label="全部"
                     value=""></el-option>
          <el-option label="已申请"
                     value="0"></el-option>
          <el-option label="处理中"
                     value="1"></el-option>
          <el-option label="已完成"
                     value="2"></el-option>
          <el-option label="失败"
                     value="3"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="类型"
                    label-width="68px">
        <el-select v-model="queryParams.type"
                   placeholder="">
          <el-option label="全部"
                     value=""></el-option>
          <el-option label="积金"
                     value="1"></el-option>
          <el-option label="收益"
                     value="2"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button v-if="authAttr>1"
                   type="success"
                   size="medium"
                   icon="el-icon-setting"
                   @click="withdrawalConfig">提现配置</el-button>
      </el-form-item>
    </el-form>
    <!-- 提现配置 -->
    <template>
      <el-dialog title="提现配置"
                 :visible.sync="withdrawalDialogVisible"
                 width="30%">
        <el-form :model="withdrawalConfigParameter"
                 ref="withdrawalConfigParameter"
                 :rules="rules"
                 label-width="140px">
          <el-form-item label="是否需要后台审核"
                        prop="isNeedExamine">
            <el-select v-model="withdrawalConfigParameter.isNeedExamine"
                       placeholder="请选择是否审核">
              <el-option label="否"
                         value="0"></el-option>
              <el-option label="是"
                         value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提现免密额度"
                        prop="noSecretLimit">
            <el-input v-model="withdrawalConfigParameter.noSecretLimit"
                      @input="changeNoSecretLimit(withdrawalConfigParameter.noSecretLimit)"
                      placeholder="请输入密码额度(免密额度（0.3-500）)"></el-input>
          </el-form-item>
          <div>注意:当需要后台审核时,提现免密额度不生效</div>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="withdrawalDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="updateRebateBillConfig('withdrawalConfigParameter')">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="id"
                           align="center"
                           label="申请编号">
          </el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="用户">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.phone,)">{{textFilter(scope.row.phone,scope.row.name)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           align="center"
                           :formatter="zt"
                           width="100px"
                           label="状态"></el-table-column>
          <el-table-column prop="type"
                           :formatter='typeFormat'
                           width="100px"
                           align="center"
                           label="类型"></el-table-column>
          <el-table-column prop="price"
                           align="center"
                           label="金额"></el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           show-overflow-tooltip
                           label="创建时间"></el-table-column>
          <el-table-column prop="remarks"
                           align="center"
                           show-overflow-tooltip
                           label="备注"></el-table-column>
          <el-table-column label="操作"
                           width="220px"
                           align="center">
            <template v-slot="{row}">
              <el-button-group>
                <el-button v-if="row.status==0"
                           type="success"
                           size="mini"
                           icon="el-icon-check"
                           @click="confirmWithdrawal(row.id)">确认提现</el-button>
                <el-button v-if="row.status==0"
                           type="danger"
                           size="mini"
                           icon="el-icon-close"
                           @click="rejectWithdrawal(row.id)">驳回申请</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getWithdrawalRecordList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        type: ''
      },
      tableData: [],
      total: 0,
      authAttr: '',
      withdrawalConfigParameter: {
        isNeedExamine: '',
        noSecretLimit: ''
      },
      withdrawalDialogVisible: false,
      rules: {
        isNeedExamine: [{ required: true, message: '请选择是否审核的选项', trigger: 'change' }],
        noSecretLimit: [{ required: true, message: '请输入免密额度（0.3-500）', trigger: 'blur' }]
      }
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getWithdrawalRecordList()
    this.getAuthAttr()
  },

  methods: {
    changeNoSecretLimit(val) {
      this.withdrawalConfigParameter.noSecretLimit = this.numberMatching(val)
    },
    updateRebateBillConfig(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.withdrawalDialogVisible = false
          const { data: res } = await this.$http.post('/admin/withdrawal_record/updateWithdrawalConfig', this.withdrawalConfigParameter)
          if (res.code == 200) {
            this.withdrawalDialogVisible = false
            this.$message.success('修改提现配置成功')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    withdrawalConfig() {
      this.getWithdrawalConfig()
      this.withdrawalDialogVisible = true
    },
    async getWithdrawalConfig() {
      const { data: res } = await this.$http.get('/admin/withdrawal_record/getWithdrawalConfig')
      this.withdrawalConfigParameter.noSecretLimit = res.data.noSecretLimit
      this.withdrawalConfigParameter.isNeedExamine = res.data.isNeedExamine + ''
    },
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getWithdrawalRecordList()
    },

    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-14')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getWithdrawalRecordList() {
      const { data: res } = await this.$http.post('/admin/withdrawal_record/getWithdrawalRecordList', this.queryParams)
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    zt(row) {
      if (row.status == 0) {
        return '已申请'
      } else if (row.status == 1) {
        return '处理中'
      } else if (row.status == 2) {
        return '已完成'
      } else if (row.status == 3) {
        return '失败'
      }
    },
    typeFormat(row) {
      if (row.type == 1) {
        return '积金'
      } else if (row.type == 2) {
        return '收益'
      }
    },
    // 确认提现
    async confirmWithdrawal(_id) {
      const msg = await this.$confirm('同意此提现申请将通过微信支付直接进行打款提现！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.post('/admin/withdrawal_record/makeSureWithdrawal', { id: _id })
        if (res.code == 200) {
          this.$message.success('确认成功')
        } else {
          this.$message.warning('确认失败')
        }
        this.getWithdrawalRecordList()
      }
    },
    async rejectWithdrawal(_id) {
      const msg = await this.$confirm('驳回此次提现申请将不会进行打款提现！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.post('/admin/withdrawal_record/rejectWithdrawal', { id: _id })
        if (res.code == 200) {
          this.$message.success('确认成功')
        } else {
          this.$message.warning('确认失败')
        }
        this.getWithdrawalRecordList()
      }
    },
    // 跳转代理详情
    async goAgencyInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, pageNum: this.queryParams.pageNum, type: 5 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.asd {
  background-color: red;
}
</style>