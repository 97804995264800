<!-- 优惠码模块 -->
<template>
  <div class="hdgl">
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="90px">
      <el-form-item label="状态"
                    label-width="65px">
        <el-select size="medium"
                   v-model="queryParams.status"
                   placeholder="请选择状态"
                   clearable>
          <el-option label="有效"
                     value="0"></el-option>
          <el-option label="无效"
                     value="1"></el-option>
          <el-option label="被使用"
                     value="2"></el-option>
          <el-option label="已过期"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抵扣程度">
        <el-input size="medium"
                  v-model="queryParams.discount"
                  clearable
                  placeholder="请输入抵扣程度"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item label="创建日期">
        <el-date-picker v-model="queryParams.createTime"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button v-if="authAttr>1"
                   @click="addDialogVisible=true"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加优惠码</el-button>
        <el-button @click="exportReport"
                   type="warning"
                   plain
                   size="medium"
                   icon="el-icon-download">导出优惠码</el-button>
        <el-button v-if="authAttr>2"
                   @click="productout()"
                   type="danger"
                   plain
                   size="medium"
                   icon="el-icon-delete">清除失效优惠码</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column type="selection"
                           width="55">
          </el-table-column>
          <el-table-column prop="id"
                           align="center"
                           label="优惠码"
                           width="180"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           label="状态"
                           width="80">
            <template slot-scope="scope">
              <el-tag v-show="scope.row.status==0"
                      type="success">有效</el-tag>
              <el-tag v-show="scope.row.status==1"
                      type="info">无效</el-tag>
              <el-tag v-show="scope.row.status==2"
                      type="warning">已使用</el-tag>
              <el-tag v-show="scope.row.status==3"
                      type="danger">已过期</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="discount"
                           align="center"
                           label="抵扣程度"
                           width="80">
            <template v-slot="scope">
              <div>{{scope.row.discount*100+'%'}}</div>
            </template></el-table-column>
          <el-table-column prop="userName"
                           align="center"
                           label="使用人"
                           width="80"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="使用者手机"></el-table-column>
          <el-table-column prop="created"
                           align="center"
                           label="创建时间"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="expireTime"
                           align="center"
                           label="过期日期"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="操作"
                           v-if="authAttr>1">
            <template v-slot="scope">
              <el-button v-show="scope.row.status==0"
                         type="text"
                         icon="el-icon-delete"
                         @click="delHandler(scope.row.id)">失效</el-button>
            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getDiscountCodeList" />
        </el-row>
      </template>
    </el-card>

    <template>
      <el-dialog title="添加优惠码"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="抵扣程度"
                          prop="discount">
              <el-input v-model="recompose.discount"
                        placeholder="输入1为全免,0.9为抵扣90%(一折)，以此类推！0-1之间且不为0"></el-input>
            </el-form-item>
            <el-form-item label="数量"
                          prop="count">
              <el-input v-model="recompose.count"
                        maxlength="3"
                        placeholder="请输入1-999的数字"></el-input>
            </el-form-item>
            <el-form-item label="过期日期"
                          prop="expireTime">
              <el-date-picker v-model="recompose.expireTime"
                              align="right"
                              type="date"
                              placeholder="选择日期"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '一周',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一个月',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', date)
            }
          },
          {
            text: '三个月',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', date)
            }
          },
          {
            text: '六个月',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 180)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一年',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 365)
              picker.$emit('pick', date)
            }
          },
          {
            text: '三年',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 1095)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: '',
        discount: '',
        createTime: ''
      },
      // 表格数据
      tableData: [],
      total: 0,
      authAttr: '',
      addDialogVisible: false,
      recompose: {
        discount: '',
        count: '',
        expireTime: ''
      },
      rules: {
        discount: [{ required: true, message: '请输入抵扣程度', trigger: 'blur' }],
        count: [{ required: true, message: '请输入优惠价数量', trigger: 'blur' }],
        expireTime: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }]
      },
      idList: []
    }
  },
  mounted() {
    this.getDiscountCodeList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getDiscountCodeList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-10')
      this.authAttr = authAttr
    },
    handleSelectionChange(val) {
      var selectiList = []
      val.forEach((element) => {
        selectiList.push(element.id)
      })
      this.idList = selectiList
    },
    // 导出报告按钮
    async exportReport() {
      const { data: res } = await this.$http.post('/admin/discount/exportDiscountCode', { idList: this.idList }, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = `优惠码.xlsx`
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    // 查询表格数据
    async getDiscountCodeList() {
      const { data: res } = await this.$http.get('/admin/discount/getDiscountCodeList', { params: this.queryParams })
      if (res.code != 200) return this.$message.warning(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addDialogVisible = false
          const { data: res } = await this.$http.post('/admin/discount/generateDiscountCode', this.recompose)
          if (res.code == 200 && res.data != 0) {
            this.getDiscountCodeList()
            this.$message.success('添加优惠码成功')
          } else {
            this.$message.warning('添加优惠码失败')
          }
          this.recompose = {}
        }
      })
    },

    // 清空所有无效和已使用的优惠码
    async productout() {
      const msg = await this.$confirm('你确定要清空所有无用的优惠码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/admin/discount/delDiscountCodeList`)
        if (res.code == 200) {
          this.getDiscountCodeList()
          return this.$message.success('删除所有数据成功！')
        } else {
          return this.$message.warning('清除失败')
        }
      }
    },
    //失效优惠码
    async delHandler(id) {
      const msg = await this.$confirm('你确定要失效该优惠码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/admin/discount/invalidDiscountCode?id=${id}`)
        if (res.code == 200) {
          this.getDiscountCodeList()
          return this.$message.success('失效成功！')
        } else {
          return this.$message.warning('失效失败')
        }
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.hdgl {
  font-size: 14px;
  overflow-x: hidden;
  padding: 0 18px;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .rich_pages {
    width: 100% !important;
  }
}
</style>
<style>
.el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
</style>