<!-- 预约模块 -->
<template>
  <div>

    <!-- 数据表格 -->
    <template>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="id"
                         align="center"
                         label="预约号">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click.native="delHandler(scope.row.orderId)">{{scope.row.id}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         :formatter="zt"
                         label="状态"></el-table-column>
        <el-table-column prop="doorTime"
                         align="center"
                         :formatter="dateFormat"
                         label="上门时间"></el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="联系电话"></el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="创建时间"></el-table-column>
        <el-table-column prop="address"
                         align="center"
                         label="地址"
                         show-overflow-tooltip></el-table-column>

      </el-table>
    </template>
    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getAppointmentList" />
      </el-row>
    </template>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'UserAppointmentInfo',
  components: {
    Pagination
  },
  props: {
    phone: {
      type: String,
      default: ''
    }
  },
  watch: {
    phone() {
      this.getAppointmentList()
    }
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: ''
      },
      tableData: [],
      total: 0,
      authAttr: ''
    }
  },
  // created() {
  //   if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
  //     this.queryParams.pageNum = Number(this.$route.query.pageNum)
  //   } else {
  //     this.queryParams.pageNum = 1
  //   }
  // },
  mounted() {
    this.queryParams.phone = this.phone
    this.getAppointmentList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getAppointmentList()
    },
    dateFormat(row, column) {
      var dT = new Date(row.doorTime) //row 表示一行数据, dateTime 表示要格式化的字段名称
      return dT.getFullYear() + '-' + (dT.getMonth() + 1) + '-' + dT.getDate() + ' ' + dT.getHours() + ':' + dT.getMinutes() + ':' + dT.getSeconds()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-7')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getAppointmentList() {
      const { data: res } = await this.$http.get('/admin/appointment/getAppointmentList', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    zt(row) {
      if (row.status == 0) {
        return '已取消'
      } else if (row.status == 1) {
        return '已预约'
      } else if (row.status == 2) {
        return '已确认'
      } else if (row.status == 3) {
        return '已完成'
      }
    },
    typeFormat(row) {
      if (row.type == 1) {
        return '血液'
      } else if (row.type == 2) {
        return '尿液'
      } else if (row.type == 3) {
        return '唾液'
      } else {
        return '未知'
      }
    },
    // 详情
    async delHandler(orderId) {
      this.$router.push({ path: 'particulars', query: { orderId: orderId, particularsType: 3 } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>