import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import login from '../views/login.vue';
import Home from '../views/Home.vue';
import Welcome from '../views/Welcome.vue';
// 管理员模块
import personnel from '../views/management-center/admin/personnel.vue';
import role from '../views/management-center/admin/role.vue';
import auth from '../views/management-center/admin/auth.vue';
import adminAuth from '../views/management-center/admin/adminAuth.vue';
// 用户管理
import user from '../views/management-center/user/user.vue';
// 产品管理
import product from '../views/management-center/product/product.vue';
import productParticulars from '../views/management-center/product/productParticulars.vue';
// 订单管理
import order from '../views/management-center/order/order.vue';

// 样本管理
import sample from '../views/management-center/sample/sample.vue';
// 预约管理
import appointment from '../views/management-center/appointment/appointment.vue';
// 推荐记录管理
import record from '../views/management-center/recommend/record.vue';
// 优惠码管理
import promotionCode from '../views/management-center/promotionCode/promotionCode.vue';
// vip卡管理
import vip from '../views/management-center/vip/vip.vue';
// 信息采集管理
import informationCollection from '../views/management-center/informationCollection/informationCollection.vue';
// 样本采集管理
import sampleCollection from '../views/management-center/sample/sampleCollection.vue';
// 个人信息
import set from '../views/management-center/set/set.vue';
// 用户详情
import userInfo from '../views/management-center/userInfo/userInfo.vue';
// 订单详情
import particulars from '@/views/management-center/particulars/index.vue';
// 代理
import agency from '@/views/management-center/agency/agency.vue';
// 提现
import withdraw from '@/views/management-center/withdraw/withdraw.vue';
//团队管理
import team from '@/views/management-center/team/team.vue';

// 用户资产重定向
import redirect from '../views/management-center/redirect/redirect.vue';
import recordParticulars from '../views/management-center/userInfo/userRecordParticulars.vue';

// 销售
import sellerAppointment from '../views/management-sales/appointment/appointment.vue';
import seller from '../views/management-sales/seller/seller.vue';
import userOrder from '../views/management-sales/userOrder/userOrder.vue';
import cancelTheReservation from '../views/management-sales/appointment/cancelTheReservation.vue';
const router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: (to) => {
				var tokenStr = window.sessionStorage.getItem('token');
				if (tokenStr == null) {
					return '/login';
				} else {
					return '/index';
				}
			}
		},
		{
			path: '/login',
			component: login,
			name: 'login',
			meta: { title: '登录' }
		},
		{
			path: '/',
			component: Home,
			name: 'Home',
			meta: { title: '首页' },
			children: [
				{ path: '/index', component: Welcome, name: 'Welcome', meta: { title: '首页' }, alias: '/index' },
				{ path: '/admin/personnel', component: personnel, name: 'personnel', meta: { title: '人员管理' } },
				{ path: '/admin/auth', component: auth, name: 'auth', meta: { title: '权限管理' } },
				{ path: '/user', component: user, name: 'user', meta: { title: '用户管理' } },
				{ path: '/admin/role', component: role, name: 'role', meta: { title: '角色管理' } },
				{ path: '/admin/adminAuth', component: adminAuth, name: 'adminAuth', meta: { title: '个人权限配置' } },
				{ path: '/userInfo', component: userInfo, name: 'userInfo', meta: { title: '用户详情' } },
				{ path: '/order', component: order, name: 'order', meta: { title: '订单管理' } },
				{ path: '/product', component: product, name: 'product', meta: { title: '产品管理' } },
				{ path: '/productParticulars', component: productParticulars, name: 'productParticulars', meta: { title: '产品详情' } },
				{ path: '/sample', component: sample, name: 'sample', meta: { title: '样本管理' } },
				{ path: '/sampleCollection', component: sampleCollection, name: 'sampleCollection', meta: { title: '样本采集' } },
				{ path: '/recommend/record', component: record, name: 'record', meta: { title: '推荐记录管理' } },
				{ path: '/recommend/recordParticulars', component: recordParticulars, name: 'recordParticulars', meta: { title: '推荐详情' } },
				{ path: '/appointment', component: appointment, name: 'appointment', meta: { title: '预约管理' } },
				{ path: '/promotionCode', component: promotionCode, name: 'promotionCode', meta: { title: '优惠码管理' } },
				{ path: '/set', component: set, name: 'set', meta: { title: '设置' } },
				{ path: '/seller', component: seller, name: 'seller', meta: { title: '我的用户' } },
				{ path: '/userOrder', component: userOrder, name: 'userOrder', meta: { title: '用户下单' } },
				{ path: '/informationCollection', component: informationCollection, name: 'informationCollection', meta: { title: '信息采集' } },
				{ path: '/vip', component: vip, name: 'vip', meta: { title: 'VIP卡管理' } },
				{ path: '/redirect', component: redirect, name: 'redirect' },
				{ path: '/particulars', component: particulars, name: 'particulars', meta: { title: '详情管理' } },
				{ path: '/sellerAppointment', component: sellerAppointment, name: 'sellerAppointment', meta: { title: '预约管理' } },
				{ path: '/agency', component: agency, name: 'agency', meta: { title: '代理管理' } },
				{ path: '/withdraw', component: withdraw, name: 'withdraw', meta: { title: '提现管理' } },
				{ path: '/team', component: team, name: 'team', meta: { title: '团队管理' } },
				{ path: '/cancelTheReservation', component: cancelTheReservation, name: 'cancelTheReservation', meta: { title: '预约详情' } }
			]
		},
		{ path: '*', redirect: '/index' }
	]
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to 将要访问的路径
	// from 代表从哪个路径跳转而来
	// next 是一个函数， 表示放行   ①next()放行   ②next('/login')强制跳转
	const token = window.sessionStorage.getItem('token');
	if (to.path === '/login') {
		if (token != null) {
			console.log('token！=null');
			return next('/index');
		}
		console.log('访问登录直接放行');
		return next();
	} // 访问登录页，直接放行
	// 获取token

	// 如果token过期了
	if (!token) {
		if (to.path == '/login') return next();
		// 注意要import element的Message组件
		alert('登录状态过期，请重新登录');
		return next('/login');
		// 如果token没有过期，又是选择了登录页面就直接重定向到首页，不需要重新输入账户密码
	} else if (to.path == '/login') {
		return next('');
	}
	// if (!token) {
	// 	console.log('没有token强制跳转');
	// 	return next('/login'); // 没有token 强制跳转
	// }

	// 获取存储token的开始时间
	const tokenStartTime = window.sessionStorage.getItem('tokenStartTime');
	// 后台给出的token有效时间，一个星期 单位 是秒
	// 我们自己定义6个小时过期，让用户重新登录一下， 用户总不可能在一个页面挂机一天吧
	const timeOver = 21600000; //21600000
	// console.log(timeOver);
	// 当前时间
	let date = new Date().getTime();
	// 如果大于说明是token过期了
	if (date - tokenStartTime > timeOver) {
		sessionStorage.removeItem('token');
		token = null;
	}
	next(); // 否则直接放行
});

export default router;
