<!-- 样本采集 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">

      <el-form-item label="姓名/编号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入姓名或编号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>

      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="code"
                           align="center"
                           label="试管编号"></el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="姓名">
          </el-table-column>
          <el-table-column prop="remarks"
                           align="center"
                           label="备注">
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getSampleCollectList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      }
    }
  },
  mounted() {
    this.getSampleCollectList()
  },
  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getSampleCollectList()
    },
    // 查询表格数据
    async getSampleCollectList() {
      const { data: res } = await this.$http.get('/admin/sample/getSampleCollectList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    }
  }
}
</script>
<style lang='scss' scoped>
</style>