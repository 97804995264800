<!--首页 -->
<template>
  <div class="information">
    <h1>欢迎登录！</h1>
  </div>
</template>

<script>
// 引入echarts
import echarts from 'echarts'
export default {
  data() {
    return {}
  },
  mounted() {}
}
</script>
<style lang='scss' scoped>
.information {
  width: 100%;
  height: 100%;
  .img {
    width: 100%;
    height: 100%;
  }
}
</style>