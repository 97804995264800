<!-- 订单模块 -->
<template>
  <div class="dtgl">
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="类型"
                    label-width="60px">
        <el-select size="medium"
                   v-model="queryParams.type"
                   placeholder="请选择类型">
          <el-option label="所有订单"
                     value="0"></el-option>
          <el-option label="未付款"
                     value="1"></el-option>
          <el-option label="已付款"
                     value="2"></el-option>
          <el-option label="已退款"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报告状态">
        <el-select size="medium"
                   v-model="queryParams.reportStatus"
                   placeholder="请选择报告状态"
                   clearable>
          <el-option label="未采样"
                     value="0"></el-option>
          <el-option label="未出报告"
                     value="1"></el-option>
          <el-option label="已出报告"
                     value="2"></el-option>
          <el-option label="已邮寄报告"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源"
                    label-width="60px">
        <el-select size="medium"
                   v-model="queryParams.source"
                   placeholder="请选择来源"
                   clearable>
          <el-option label="销售小程序"
                     value="1"></el-option>
          <el-option label="产品小程序"
                     value="2"></el-option>
          <el-option label="线下"
                     value="3"></el-option>
          <el-option label="京东"
                     value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>

      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <template>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="id"
                         align="center"
                         label="订单号"
                         width="150">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click.native="delHandler(scope.row.id)">{{scope.row.id}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="productName"
                         align="center"
                         label="产品名称"
                         width="150"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="billNo"
                         align="center"
                         label="账单号"
                         width="190"></el-table-column>
        <el-table-column prop="price"
                         align="center"
                         label="价格"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="status"
                         align="center"
                         :formatter="zt"
                         label="订单状态"></el-table-column>
        <el-table-column prop="reportStatus"
                         align="center"
                         :formatter="reportStatus"
                         label="报告状态"></el-table-column>
        <el-table-column prop="sampleIdList"
                         align="center"
                         show-overflow-tooltip
                         label="样本">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click.native="gotoSample(scope.row.id)">查看样本</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="source"
                         align="center"
                         label="来源">
          <template slot-scope="scope">
            <span v-show="scope.row.source==1">销售小程序</span>
            <span v-show="scope.row.source==2">产品小程序</span>
            <span v-show="scope.row.source==3">线下</span>
            <span v-show="scope.row.source==4">京东</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="创建时间"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.createTime| datefmt('YYYY-MM-DD HH:mm')}}</span>
          </template>
        </el-table-column>

      </el-table>
    </template>
    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getOrderList" />
      </el-row>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'UserOrderInfo',
  components: {
    Pagination
  },
  props: {
    phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0',
        phone: '',
        source: '',
        reportStatus: ''
      },
      tableData: [],
      total: 0,
      xqlist: {},
      authAttr: ''
    }
  },

  mounted() {
    this.queryParams.phone = this.phone
    this.getOrderList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getOrderList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getOrderList() {
      const { data: res } = await this.$http.get('/admin/order/getOrderList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    //判断身份认证状态
    lx(row) {
      if (row.payType == 1) {
        return '支付宝'
      } else if (row.payType == 2) {
        return '微信'
      } else if (row.payType == 3) {
        return '银行卡'
      } else {
        return '现金'
      }
    },
    zt(row) {
      if (row.status == 0) {
        return '未付款'
      } else if (row.status == 1) {
        return '已付款'
      } else {
        return '已退款'
      }
    },
    // 报告状态
    reportStatus(row) {
      if (row.reportStatus == 1) {
        return '未出报告'
      } else if (row.reportStatus == 2) {
        return '已出报告'
      } else if (row.reportStatus == 3) {
        return '已邮寄报告'
      } else {
        return '未采样'
      }
    },

    // 跳转订单详情
    async delHandler(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, particularsType: 1 } })
    },
    // 跳转样本详情
    gotoSample(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, particularsType: 2 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.pagination {
  padding-top: 10px;
  text-align: right;
}
</style>