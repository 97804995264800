<!-- 权限管理 -->
<template>
  <div>
    <el-form label-width="80px"
             ref="myform"
             size="small"
             :inline="true">
      <el-form-item label="筛选权限"
                    label-width="100px">
        <el-select v-model="queryParams.type"
                   placeholder="请选择权限类型"
                   clearable>
          <el-option label="文章模块"
                     value="1"></el-option>
          <el-option label="用户模块"
                     value="2"></el-option>
          <el-option label="订单模块"
                     value="3"></el-option>
          <el-option label="产品模块"
                     value="4"></el-option>
          <el-option label="管理员模块"
                     value="5"></el-option>
          <el-option label="样本模块"
                     value="6"></el-option>
          <el-option label="预约模块"
                     value="7"></el-option>
          <el-option label="报告模块"
                     value="8"></el-option>
          <el-option label="知情同意模块"
                     value="9"></el-option>
          <el-option label="优惠码模块"
                     value="10"></el-option>
          <el-option label="资产模块"
                     value="11"></el-option>
          <el-option label="信息采集模块"
                     value="12"></el-option>
          <el-option label="VIP卡模块"
                     value="13"></el-option>
        </el-select>

      </el-form-item>

      <el-form-item>
        <el-select v-model="queryParams.attribute"
                   placeholder="请选择权限属性"
                   clearable>
          <el-option label="读权限"
                     value="1"></el-option>
          <el-option label="写权限"
                     value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryParams.identity"
                   placeholder="请选择权限身份"
                   clearable>
          <el-option label="产品管理员"
                     value="2"></el-option>
          <el-option label="销售管理员"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="medium"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="authorityList"
              align="center"
              style="width: 100%"
              :header-cell-style="{background: '#f8f8f9'}">
      <el-table-column prop="type"
                       align="center"
                       label="权限类型"
                       :formatter="permissiontype">
      </el-table-column>
      <el-table-column label="属性"
                       align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.attribute"
                     active-color="#1E90FF"
                     inactive-color="#13ce66"
                     :active-value="2"
                     :inactive-value="1"
                     active-text="写"
                     inactive-text="读"
                     @change="changeAttr(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="identityName"
                       align="center"
                       label="身份">
        <template slot-scope="scope">
          <span v-show="scope.row.identityName=='ROLE_ADMIN_PRODUCT'">产品管理员</span>
          <span v-show="scope.row.identityName=='ROLE_ADMIN_SELLER'">销售管理员</span>

        </template>
      </el-table-column>

      <el-table-column prop="status"
                       align="center"
                       label="状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     :active-value="0"
                     :inactive-value="1"
                     active-text="启用"
                     inactive-text="禁用"
                     @change="updateBrandStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="remarks"
                       align="center"
                       label="备注">
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authorityList: [],
      queryParams: {
        type: '',
        attribute: '',
        identity: ''
      }
    }
  },
  mounted() {
    this.getAuthList()
  },
  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getAuthList()
    },
    async changeAttr(row) {
      const { data: res } = await this.$http.post(`/admin/admin/setAuthAttr?id=${row.id}&attribute=${row.attribute}`)
      if (res.code == 200 && res.data == 1) return this.$message.success('设置成功')
      this.$message.error('设置失败')
    },
    async getAuthList() {
      const { data: res } = await this.$http.get(`/admin/admin/getAuthList?type=${this.queryParams.type}&attribute=${this.queryParams.attribute}&identity=${this.queryParams.identity}`)
      this.authorityList = res.data
      if (res.code != 200) return this.$message.error('权限不够哦！')
    },

    // 判断属性
    messageTypeFormat(row) {
      if (row.attribute == 1) {
        return '读'
      } else {
        return '写'
      }
    },
    // 判断权限类型
    permissiontype(row) {
      if (row.type == 1) {
        return '文章'
      } else if (row.type == 2) {
        return '用户'
      } else if (row.type == 3) {
        return '订单'
      } else if (row.type == 4) {
        return '产品'
      } else if (row.type == 5) {
        return '管理员'
      } else if (row.type == 6) {
        return '样本'
      } else if (row.type == 7) {
        return '预约'
      } else if (row.type == 8) {
        return '报告'
      } else if (row.type == 9) {
        return '知情同意书'
      } else if (row.type == 10) {
        return '优惠码'
      } else if (row.type == 11) {
        return '资产'
      } else if (row.type == 12) {
        return '信息采集'
      } else if (row.type == 13) {
        return 'VIP卡'
      } else if (row.type == 14) {
        return '代理'
      }
    },
    // 修改权限状态
    async updateBrandStatus(row) {
      const { data: res } = await this.$http.post(`/admin/admin/setAuthStatus?id=${row.id}&status=${row.status}`)
    }
  }
}
</script>
<style lang='scss' scoped>
</style>