<template>
  <!--  vip -->
  <div>
    <!-- 顶部搜索 -->
    <el-form ref="myform"
             size="small"
             :inline="true"
             label-width="58px">
      <el-form-item label="卡号"
                    prop="keyword">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入卡号"
                  @keyup.enter.native="inquire"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="状态"
                    label-width="68px">
        <el-select v-model="queryParams.status"
                   clearable
                   placeholder="请选择激活状态">
          <el-option label="未激活"
                     value="0"></el-option>
          <el-option label="已激活"
                     value="1"></el-option>
          <el-option label="已使用"
                     value="2"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button v-if="authAttr>1"
                   @click="openVipDialog()"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加VIP卡</el-button>
        <el-button v-if="authAttr>1"
                   type="success"
                   @click="openSampleBox()"><i class="el-icon-upload"></i>导入VIP卡</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <!-- 表格区域 -->
      <el-table :data="vipList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="number"
                         align="center"
                         label="卡号">
        </el-table-column>
        <el-table-column prop="discount"
                         align="center"
                         label="折扣">
          <template slot-scope="scope">
            {{scope.row.discount*100}}%
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         label="状态">
          <template slot-scope="scope">
            <span v-show="scope.row.status==0">未激活</span>
            <span v-show="scope.row.status==1">已激活</span>
            <span v-show="scope.row.status==2">已使用</span>

          </template>
        </el-table-column>
        <el-table-column prop="userPhone"
                         align="center"
                         label="使用者手机号">
        </el-table-column>
        <el-table-column prop="userName"
                         align="center"
                         label="使用人">
        </el-table-column>
        <el-table-column prop="productName"
                         align="center"
                         label="适用产品">
          <template slot-scope="scope">
            <span v-if="scope.row.productName==null||scope.row.productName==''">通用</span>
            <span v-else>{{scope.row.productName}}</span>

          </template>
        </el-table-column>
        <el-table-column prop="useTime"
                         align="center"
                         label="使用时间">
        </el-table-column>

        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button-group>
              <el-button v-if="row.status!=2"
                         type="primary"
                         size="mini"
                         icon="el-icon-edit"
                         @click="modificationDiscount(row)">修改</el-button>
              <el-button v-if='row.status==0&&authAttr>1'
                         type="success"
                         size="mini"
                         icon="el-icon-tickets"
                         @click="validVipCard(row.number)">激活</el-button>
              <el-button v-if="authAttr>2&&row.status!=2"
                         @click="delVipCard(row.number)"
                         type="danger"
                         size="mini"
                         icon="el-icon-delete">删除</el-button>
            </el-button-group>

          </template>
        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getVipCardList" />

        </el-row>
      </template>
    </el-card>
    <!-- 导入vip卡 -->
    <template>
      <el-dialog title="导入vip卡"
                 :visible.sync="bulkImportSample"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="addSamplelist"
                   label-width="125px">
            <el-form-item label="导入样本"
                          prop="introduction">
              <el-upload class="upload-demo"
                         name="report"
                         ref="upload2"
                         multiple
                         action="#"
                         :limit=InfoCatalogUserBlock
                         :on-change="sampleFileChange"
                         :headers="headers"
                         :auto-upload="false">
                <el-button slot="trigger"
                           size="small"
                           type="primary">选择文件</el-button>
              </el-upload>
              <div style="color:coral">1.最少导入一条VIP的信息，卡号、密码、折扣必填，否则不能导入数据。</div>
              <div style="color:coral">2.文件必须为以xlsx，xls结尾的excel表格，且文件不能超过20M。</div>
              <div style="color:coral">3.折扣说明：输入1为全免,0.9为抵扣90%(一折)，以此类推！0-1之间且不为0</div>
            </el-form-item>
            <div><el-button type="warning"
                         @click="downloadTemplateExcel()"><i class="el-icon-download"></i>下载VIP模板</el-button></div>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="bulkImportSample = false">取 消</el-button>
          <el-button type="success"
                     :disabled="buyFlag"
                     @click="importVipCardByExcel()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加vip卡 -->
    <template>
      <el-dialog title="添加VIP卡"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="卡号"
                          prop="number">
              <el-input v-model="recompose.number"
                        placeholder="请输入卡号"></el-input>
            </el-form-item>
            <el-form-item label="密码"
                          prop="password">
              <el-input v-model="recompose.password"
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="抵扣程度"
                          prop="discount">
              <el-input v-model="recompose.discount"
                        placeholder="输入1为全免,0.9为抵扣90%(一折)，以此类推！0-1之间且不为0"></el-input>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select size="medium"
                         v-model="recompose.status"
                         placeholder="请选择激活状态">
                <el-option label="不激活"
                           value="0"></el-option>
                <el-option label="激活"
                           value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="isGeneral"
                          label="适用产品">
              <el-select size="medium"
                         clearable
                         v-model="recompose.productId"
                         placeholder="请选择适用产品">
                <el-option v-for="item in productAddableList "
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked"
                           @change=checkBox>是否通用</el-checkbox>
              </el-select>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改折扣 -->
    <template>
      <el-dialog title="修改折扣"
                 :visible.sync="discountDialogs"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="discountList"
                   ref="discountList"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="抵扣程度"
                          prop="discount">
              <el-input v-model="discountList.discount"
                        placeholder="输入1为全免,0.9为抵扣90%(一折)，以此类推！0-1之间且不为0"></el-input>
            </el-form-item>
            <el-form-item v-if="isModifyGeneral"
                          label="适用产品">
              <el-select size="medium"
                         clearable
                         v-model="discountList.productId"
                         placeholder="请选择适用产品">
                <el-option v-for="item in productAddableList "
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="modifySelection"
                           @change=checkModifyBox>是否通用</el-checkbox>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="discountDialogs = false">取 消</el-button>
          <el-button type="success"
                     @click="submitDiscount('discountList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      // 上传文件/图片请求消息头
      headers: {
        token: sessionStorage.getItem('token')
      },
      recompose: {
        discount: '',
        number: '',
        password: '',
        status: '',
        productId: ''
      },
      discountList: {
        number: '',
        discount: '',
        productId: ''
      },
      uploadingSample: { file: '' },
      // 导入上传数量
      InfoCatalogUserBlock: 1,
      channelList: {
        userId: '',
        sellerId: ''
      },
      sellerList: [],
      id: '',
      name: '',
      vipList: [],
      xqlist: {},
      //获取用户列表传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        status: ''
      },
      total: 0,
      addDialogVisible: false,
      buyFlag: false,
      bulkImportSample: false,
      // 控制修改密码
      addDialogVisible: false,
      // 对话框
      discountDialogs: false,
      //获取用户详情
      details: false,
      queryParams2: {
        pageNum: 1,
        pageSize: 100,
        type: 4
      },
      authAttr: '',
      rules: {
        discount: [{ required: true, message: '请输入抵扣程度', trigger: 'blur' }],
        number: [{ required: true, message: '请输入卡号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        status: [{ required: true, message: '请选择激活状态', trigger: 'change' }]
      },
      productAddableList: [],
      isGeneral: false,
      checked: true,
      modifySelection: true,
      isModifyGeneral: false
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getVipCardList()
    this.getAuthAttr()
  },
  methods: {
    checkModifyBox(val) {
      if (val) {
        this.isModifyGeneral = false
      } else {
        this.isModifyGeneral = true
      }
      this.discountList.productId = ''
    },
    checkBox(val) {
      if (val) {
        this.isGeneral = false
      } else {
        this.isGeneral = true
      }
      this.recompose.productId = ''
    },
    openVipDialog() {
      this.addDialogVisible = true
      this.getAddableProductList()
    },
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getVipCardList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-13')
      this.authAttr = authAttr
    },
    // 查询vip卡列表
    async getVipCardList() {
      const { data: res } = await this.$http.get('/admin/vip/getVipCardList', { params: this.queryParams })
      this.vipList = res.data.list
      this.total = res.data.total
    },
    // 获取vip卡可添加产品
    async getAddableProductList() {
      const { data: res } = await this.$http.get('/admin/vip/getAddableProductList')
      this.productAddableList = res.data
    },
    // 激活vip卡
    async validVipCard(number) {
      const { data: res } = await this.$http.get(`/admin/vip/validVipCard?number=${number}`)
      if (res.code == 200) {
        this.getVipCardList()
        this.$message.success('激活VIP卡成功!')
      } else {
        this.$message.error('激活VIP卡失败')
      }
    },
    // 删除vip卡
    async delVipCard(number) {
      const msg = await this.$confirm(`你确定要删除卡号${number}的VIP卡吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/vip/delVipCard?number=${number}`)
        if (res.code == 200) {
          this.$message.success('删除成功!')
          this.getVipCardList()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 下载导出vip卡excel模板
    downloadTemplateExcel() {
      var url = `${this.$baseurl}/admin/vip/downloadTemplateExcel`
      window.open(url)
    },
    sampleFileChange(uploadFile) {
      this.uploadingSample.file = uploadFile.raw
    },
    openSampleBox() {
      this.bulkImportSample = true
    },
    async importVipCardByExcel() {
      let fileFormData = new FormData()
      fileFormData.append('file', this.uploadingSample.file)
      const { data: res } = await this.$http.post(`/admin/vip/importVipCardByExcel`, fileFormData)
      if (res.code == 200) {
        this.getVipCardList()
        this.$message.success('导入成功')
      } else {
        this.$message.error(res.msg)
      }
      this.$refs.upload2.clearFiles()
      this.bulkImportSample = false
    },
    // 添加vip卡
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addDialogVisible = false
          const { data: res } = await this.$http.post('/admin/vip/addVipCard', this.recompose)
          if (res.code == 200) {
            this.getVipCardList()
            this.$message.success('添加VIP卡成功')
          } else {
            this.$message.warning(res.msg)
          }
        }
      })
    },
    modificationDiscount(row) {
      if (row.productId == null || row.productId == '') {
        this.modifySelection = true
        this.isModifyGeneral = false
      } else {
        this.modifySelection = false
        this.isModifyGeneral = true
      }
      this.getAddableProductList()
      this.discountDialogs = true
      this.discountList.number = row.number
      this.discountList.productId = row.productId
      this.discountList.discount = row.discount
    },
    submitDiscount(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.discountDialogs = false
          const { data: res } = await this.$http.post(`/admin/vip/modifyVipCard`, this.discountList)
          if (res.code == 200) {
            this.getVipCardList()
            this.discountList.discount = ''
            this.$message.success('修改折扣成功')
          } else {
            this.discountList.discount = ''
            this.$message.warning(res.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>