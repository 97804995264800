<template>
  <!--  用户管理 -->
  <div>
    <!-- 顶部搜索 -->
    <el-form size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="手机号/姓名"
                    prop="keyword">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入手机号或姓名"
                  @keyup.enter.native="inquire"
                  clearable></el-input>
      </el-form-item>

      <el-form-item label="销售姓名"
                    prop="sellerName">
        <el-input v-model="queryParams.seller"
                  placeholder="请输入销售姓名"
                  @keyup.enter.native="inquire"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="用户来源">
        <el-select v-model="queryParams.source"
                   placeholder="请选择用户来源"
                   clearable>
          <el-option label="销售端"
                     value="1"></el-option>
          <el-option label="产品端"
                     value="2"></el-option>
          <el-option label="线下"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <!-- 表格区域 -->
      <el-table :data="userList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="phone"
                         align="center"
                         label="手机">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click.native="goto(scope.row.phone)">{{scope.row.phone}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         align="center"
                         label="姓名">

        </el-table-column>
        <el-table-column prop="age"
                         width="50"
                         align="center"
                         label="年龄">
        </el-table-column>
        <el-table-column prop="gender"
                         width="50"
                         align="center"
                         label="性别"
                         :formatter="sex">
        </el-table-column>
        <el-table-column prop="sellerName"
                         align="center"
                         label="销售">
        </el-table-column>

        <el-table-column prop="source"
                         align="center"
                         label="来源">
          <template slot-scope="scope">
            <span v-show="scope.row.source==1">销售端</span>
            <span v-show="scope.row.source==2">产品端</span>
            <span v-show="scope.row.source==3">线下</span>

          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         label="状态">
          <template slot-scope="scope">
            <span v-if="authAttr<=1">
              <span v-if="scope.row.status==1">正常</span>
              <span v-if="scope.row.status==2">已锁定</span>

            </span>
            <span v-if="scope.row.status==0">未注册</span>
            <span v-if="scope.row.status==3">已注销</span>
            <el-switch v-if="scope.row.status!=3&&scope.row.status!=0&&authAttr>1"
                       v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="1"
                       :inactive-value="2"
                       active-text="正常"
                       inactive-text="锁定"
                       @change="updateBrandStatus(scope.row)"></el-switch>
          </template>

        </el-table-column>
        <el-table-column prop="createdTime"
                         show-overflow-tooltip
                         align="center"
                         label="注册时间">
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button-group>

              <el-button @click="changeAddDialogVisible(row.id,row.seller)"
                         type="text"
                         size="mini"
                         icon="el-icon-edit"
                         v-if="row.status!=3&&row.status!=0&&authAttr>1">修改销售</el-button>

            </el-button-group>

          </template>

        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getUserList" />

        </el-row>
      </template>
    </el-card>

    <!-- 修改用户渠道对话框 -->
    <template>
      <el-dialog title="修改用户渠道"
                 :visible.sync="modifyingDialogVisible"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="channelList"
                   ref="channelList"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="用户id">
              <el-input v-model="channelList.userId"
                        disabled></el-input>
            </el-form-item>
            <el-form-item label="销售名"
                          prop="sellerId">
              <template>
                <el-select v-model="channelList.sellerId"
                           placeholder="请输入销售名">
                  <el-option v-for="item in sellerList"
                             :key="item.id"
                             :label="item.username"
                             :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="modifyingDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitHandler('channelList')">确 定</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      channelList: {
        userId: '',
        sellerId: ''
      },
      sellerList: [],
      id: '',
      name: '',
      userList: [],
      //获取用户列表传参
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        keyword: '',
        seller: '',
        source: ''
      },
      total: 0,
      // 控制修改渠道
      modifyingDialogVisible: false,
      queryParams2: {
        pageNum: 1,
        pageSize: 100,
        type: 4
      },
      authAttr: '',
      rules: {
        sellerId: [{ required: true, message: '请选择销售名', trigger: 'change' }]
      },
      srcList: []
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getUserList()
    this.getAuthAttr()
  },
  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getUserList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-2')
      this.authAttr = authAttr
    },
    // 点击修改渠道名获取销售
    async changeAddDialogVisible(id, sellerId) {
      this.modifyingDialogVisible = true
      this.channelList.userId = id
      this.channelList.sellerId = sellerId
      const { data: res } = await this.$http.get('/admin/admin/getAdminList', { params: this.queryParams2 })
      this.sellerList = res.data.list
    },
    // 确认修改渠道按钮
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/user/updateUserSeller?id=${this.channelList.userId}&seller=${this.channelList.sellerId}`)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.getUserList()
          this.modifyingDialogVisible = false
        }
      })
    },
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.get('/admin/user/getUserList', { params: this.queryParams })
      this.userList = res.data.list
      this.srcList = []
      res.data.list.forEach((e) => {
        if (e.avatar != null && e.avatar != '') {
          this.srcList.push(this.$url + e.avatar)
        }
      })
      this.total = res.data.total
    },
    //
    sex(row) {
      if (row.gender == 0) {
        return '男'
      } else if (row.gender == 1) {
        return '女'
      }
    },
    // 修改状态
    async updateBrandStatus(row) {
      const { data: res } = await this.$http.post(`/admin/user/updateUserStatus?id=${row.id}&status=${row.status}`)
      this.getUserList()
    },
    async goto(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, pageNum: this.queryParams.pageNum, type: 1 } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>