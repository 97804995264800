<!-- 样本详情 -->
<template>
  <div>
    <div v-if=sampleStae
         style="margin: 10px 0px">此订单无样本</div>
    <el-button type="success"
               v-if="sampleAuth>1&&processStatus!=1"
               size="small"
               @click="addSample=true">添加样本</el-button>
    <div v-if=!sampleStae>
      <el-card v-for="item in sampleList"
               :key="item.id">
        <el-descriptions>
          <el-descriptions-item label="样本编号">
            <span v-if="!item.sampleNo"
                  style="color:red">未填写</span>
            <span v-else>{{item.sampleNo}}</span>

          </el-descriptions-item>
          <el-descriptions-item label="被采样人手机号"> {{item.phone}}</el-descriptions-item>
          <el-descriptions-item label="试管编号"> {{item.number}}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag size="small">{{item.status==1?"已取样":item.status==2?'实验室处理中':item.status==3?'测序中':item.status==4?'数据分析中':item.status==5?'已完成':'未知状态'}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="受检人">{{item.name}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{item.createTime}}</el-descriptions-item>
          <el-descriptions-item label="备注">{{item.remarks}}</el-descriptions-item>
          <el-descriptions-item label="知情同意书">
            <div v-if="item.informedConsentFile==''||item.informedConsentFile==null">无</div>
            <a v-else
               target="_blank"
               style="color:#66b1ff"
               :href="$url+item.informedConsentFile+'?key='+token"
               type="primary">查看知情同意书</a>
          </el-descriptions-item>
        </el-descriptions>
        <el-button v-if="sampleAuth>1"
                   size="small"
                   @click="modifySampleInfo(item)"
                   type="primary">修改信息</el-button>
        <el-button v-if="sampleAuth>2"
                   size="small"
                   @click="invalidSample(item.id)"
                   type="danger">失效样本</el-button>
      </el-card>
    </div>
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="uploadFlag"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form ref="sampleInfo"
                   :model="sampleInfo"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="样本编号">
              <el-input placeholder="请输入样本编号"
                        v-model="sampleInfo.sampleNo"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="受检者手机号">
              <el-input placeholder="请输入受检者手机号"
                        v-model="sampleInfo.phone"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="试管编号"
                          prop="number">
              <el-input placeholder="请输入试管编号"
                        v-model="sampleInfo.number"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="受检者"
                          prop="name">
              <el-input placeholder="请输入受检者姓名"
                        v-model="sampleInfo.name"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea"
                        placeholder="请输入内容"
                        v-model="sampleInfo.remarks"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="样本状态">
              <el-select v-model="sampleInfo.status"
                         placeholder="">
                <el-option label="已取样"
                           :value="1"></el-option>
                <el-option label="实验室处理中"
                           :value="2"></el-option>
                <el-option label="测序中"
                           :value="3"></el-option>
                <el-option label="数据分析中"
                           :value="4"></el-option>
                <el-option label="已完成"
                           :value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="uploadFlag = false">取 消</el-button>
          <el-button type="primary"
                     @click="updateInfo('sampleInfo')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 添加样本 -->
    <template>
      <el-dialog title="添加样本"
                 :visible.sync="addSample"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules1"
                   label-width="110px">
            <el-form-item label="试管编号"
                          prop="number">
              <el-input v-model="recompose.number"
                        placeholder="请输入试管编号"></el-input>
            </el-form-item>
            <el-form-item label="样本编号">
              <el-input v-model="recompose.sampleNo"
                        placeholder="请输入样本编号"></el-input>
            </el-form-item>
            <el-form-item label="受检者手机号"
                          prop="phone">
              <el-input v-model="recompose.phone"
                        placeholder="请输入受检者手机号"></el-input>
            </el-form-item>
            <el-form-item label="受检者"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入受检者姓名（必填）"></el-input>
            </el-form-item>
            <el-form-item label="样本类型"
                          prop="type">
              <el-select v-model="recompose.type"
                         placeholder="请选择样本类型">
                <el-option label="血液"
                           value="1"></el-option>
                <el-option label="尿液"
                           value="2"></el-option>
                <el-option label="唾液"
                           value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注"
                          prop="remarks">
              <el-input type="textarea"
                        v-model="recompose.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addSample = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitSample('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    processStatus: Number
  },
  name: 'Sample',
  data() {
    return {
      orderid: '',
      pageNum: '',
      sampleList: {},
      sampleAuth: '',
      sampleStae: false,
      uploadFlag: false,
      addSample: false,
      sampleInfo: {
        sampleNo: '',
        name: '',
        number: '',
        remarks: '',
        status: '',
        id: '',
        phone: ''
      },
      token: '',
      recompose: {
        number: '',
        type: '1',
        remarks: '',
        name: '',
        orderId: '',
        status: 1,
        sampleNo: '',
        phone: ''
      },
      rules1: {
        name: [{ required: true, message: '请输入受检者姓名（必填）', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入受检者手机号（必填）', trigger: 'blur' }],
        number: [{ required: true, message: '请输入试管编号（必填）', trigger: 'blur' }]
      },
      rules: {
        number: [{ required: true, message: '请输入试管编号（必填）', trigger: 'blur' }],
        name: [{ required: true, message: '请输入受检者姓名（必填）', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.token = sessionStorage.getItem('token')
    this.pageNum = this.$route.query.pageNum
    this.orderid = this.$route.query.orderId
    this.recompose.orderId = this.$route.query.orderId
    this.getSampleInfo()
    this.getAuthAttr()
  },

  methods: {
    async getSampleInfo() {
      const { data: res } = await this.$http.get(`/admin/sample/getSampleInfo?orderId=${this.orderid}`)
      if (res.code == 200) {
        if (res.data == null) {
          this.sampleStae = true
        } else {
          this.sampleList = res.data
        }
      }
    },
    modifySampleInfo(item) {
      this.uploadFlag = true
      this.sampleInfo.id = item.id
      this.sampleInfo.status = item.status
      this.sampleInfo.remarks = item.remarks
      this.sampleInfo.number = item.number
      this.sampleInfo.name = item.name
      this.sampleInfo.sampleNo = item.sampleNo
      this.sampleInfo.phone = item.phone
    },
    async invalidSample(id) {
      const msg = await this.$confirm('您确认要失效该样本吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/sample/delSample?id=${id}`)
        if (res.code != 200) {
          return this.$message.error(res.msg)
        } else {
          this.$message.success('失效成功')
          this.getSampleInfo()
          this.$emit('watchChild')
        }
      }
    },
    updateInfo(sampleInfo) {
      this.$refs[sampleInfo].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/sample/modifySampleInfo`, this.sampleInfo)
          if (res.code != 200) {
            this.$message.error(res.msg)
            this.uploadFlag = false
          } else {
            this.$message.success('修改样本成功')
            this.getSampleInfo()
            this.uploadFlag = false
            this.$emit('watchChild')
          }
        }
      })
    },
    // 获取样本权限
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      this.sampleAuth = authAttr
    },
    submitSample(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/sample/addSample`, this.recompose)
          if (res.code == 200) {
            this.$message.success('添加成功！')
            this.addSample = false
            this.sampleStae = false
            this.getSampleInfo()
            this.$emit('watchChild')
          } else {
            this.$message.error(res.msg)
            this.addSample = false
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.headline {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}
.basicsHeadline {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}
.elseHeadline {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}
</style>