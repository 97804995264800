<!-- 订单模块 -->
<template>
  <div class="dtgl">
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item label="账单号/订单号">
        <el-input size="medium"
                  v-model="queryParams.billNo"
                  clearable
                  placeholder="请输入账单号或订单号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item label="类型"
                    label-width="60px">
        <el-select size="medium"
                   v-model="queryParams.type"
                   placeholder="请选择类型">
          <el-option label="所有订单"
                     value="0"></el-option>
          <el-option label="未付款"
                     value="1"></el-option>
          <el-option label="已付款"
                     value="2"></el-option>
          <el-option label="已退款"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报告状态">
        <el-select size="medium"
                   v-model="queryParams.reportStatus"
                   placeholder="请选择报告状态"
                   clearable>
          <el-option label="未采样"
                     value="0"></el-option>
          <el-option label="未出报告"
                     value="1"></el-option>
          <el-option label="已出报告"
                     value="2"></el-option>
          <el-option label="已邮寄报告"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源"
                    label-width="60px">
        <el-select size="medium"
                   v-model="queryParams.source"
                   placeholder="请选择来源"
                   clearable>
          <el-option label="销售小程序"
                     value="1"></el-option>
          <el-option label="产品小程序"
                     value="2"></el-option>
          <el-option label="线下"
                     value="3"></el-option>
          <el-option label="京东"
                     value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button v-if="authAttr>2"
                   @click="productout()"
                   type="danger"
                   plain
                   size="medium"
                   icon="el-icon-delete">清空无效订单</el-button>
        <el-button v-if="authProduct>0&&authAttr>1"
                   type="warning"
                   size="medium"
                   icon="el-icon-upload"
                   @click="openReportBox">导入报告</el-button>
        <el-button type="success"
                   size="medium"
                   icon="el-icon-download"
                   @click="downloaddialogVisible=true">下载订单返利Excel</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">

          <el-table-column prop="id"
                           align="center"
                           label="订单号"
                           width="150"></el-table-column>
          <el-table-column prop="productName"
                           align="center"
                           label="产品名称"
                           width="150"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           :formatter="status"
                           label="订单状态"
                           width="80"></el-table-column>

          <el-table-column prop="reportStatus"
                           align="center"
                           :formatter="reportStatus"
                           label="报告状态"></el-table-column>
          <el-table-column prop="price"
                           align="center"
                           label="价格"
                           width="80"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="userName"
                           align="center"
                           label="用户">
            <template slot-scope="scope">
              <el-link v-if="scope.row.userName"
                       type="primary"
                       :underline="false"
                       @click.native="queryWarehouseHandle(scope.row.phone)">{{scope.row.userName}}</el-link>
              <el-link v-else
                       type="primary"
                       :underline="false"
                       @click.native="queryWarehouseHandle(scope.row.phone)">{{scope.row.phone}}</el-link>
            </template>

          </el-table-column>

          <el-table-column prop="payType"
                           align="center"
                           :formatter="lx"
                           label="付款类型"
                           width="80"></el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="创建时间"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.createTime| datefmt('YYYY-MM-DD HH:mm')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="source"
                           align="center"
                           label="来源"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-show="scope.row.source==1">销售小程序</span>
              <span v-show="scope.row.source==2">产品小程序</span>
              <span v-show="scope.row.source==3">线下</span>
              <span v-show="scope.row.source==4">京东</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="{row}">
              <el-button-group>
                <el-button type="text"
                           size="mini"
                           icon="el-icon-tickets"
                           @click="delHandler(row.id)">详情</el-button>
              </el-button-group>

            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getOrderList" />
        </el-row>
      </template>

      <!-- 导入报告弹出框 -->
      <template>
        <el-dialog title="导入报告"
                   :visible.sync="dialogVisible"
                   width="30%">
          <el-form :model="reportList"
                   ref="reportList"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="姓名"
                          prop="name">
              <el-input v-model="reportList.name"
                        placeholder="请输入姓名（必填）"></el-input>
            </el-form-item>
            <el-form-item label="样本编号"
                          prop="sampleNo">
              <el-input v-model="reportList.sampleNo"
                        placeholder="请输入样本编号"></el-input>
            </el-form-item>
            <el-form-item label="试管编号"
                          prop="number">
              <el-input v-model="reportList.number"
                        placeholder="请输入试管编号"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="reportList.phone"
                        placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="产品id"
                          prop="productId">
              <el-select size="medium"
                         v-model="reportList.productId"
                         placeholder="请选择产品">
                <el-option v-for="item in productIdList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年龄"
                          prop="age">
              <el-input type="number"
                        max="100"
                        min="0"
                        v-model="reportList.age"
                        placeholder="请输入年龄"
                        oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="性别"
                          prop="gender">
              <el-select size="medium"
                         v-model="reportList.gender"
                         placeholder="请选择性别">
                <el-option label="男"
                           value="0"></el-option>
                <el-option label="女"
                           value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上传文件"
                          prop="reports">
              <el-upload class="upload-demo"
                         ref="upload"
                         name="report"
                         multiple
                         action="#"
                         :on-change="fileChange"
                         :on-remove="fileRemove"
                         :on-error="uploadFalse"
                         :headers="headers"
                         :before-upload="beforeAvatarUpload"
                         :file-list="reportList.reports"
                         :data='reportList'
                         :auto-upload="false">
                <el-button slot="trigger"
                           size="small"
                           type="primary">选择文件</el-button>
              </el-upload>
              <span style="color:coral">请上传文件类型为pdf格式且大小不高于20M</span>
            </el-form-item>
          </el-form>
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary"
                       @click="submitUpload('reportList')">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!-- 下载订单返利表格 -->
      <template>
        <el-dialog title="下载订单返利Excel"
                   :visible.sync="downloaddialogVisible"
                   width="30%">
          <el-form :model="downloadFormParameter"
                   ref="downloadFormParameter"
                   :rules="rules1"
                   label-width="80px">
            <el-form-item label="开始日期"
                          prop="startTime">
              <el-date-picker v-model="downloadFormParameter.startTime"
                              align="right"
                              type="date"
                              placeholder="选择开始日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期"
                          prop="endTime">
              <el-date-picker v-model="downloadFormParameter.endTime"
                              align="right"
                              type="date"
                              placeholder="选择开始日期">
              </el-date-picker>
            </el-form-item>

          </el-form>
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="downloaddialogVisible = false">取 消</el-button>
            <el-button type="primary"
                       @click="submitDownload('downloadFormParameter')">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0',
        billNo: '',
        keyword: '',
        source: '',
        reportStatus: ''
      },
      tableData: [],
      total: 0,
      xqlist: {},
      authAttr: '',
      authReport: '',
      authProduct: '',
      productIdList: [],
      dialogVisible: false,
      downloaddialogVisible: false,
      // 添加历史报告
      reportList: {
        name: '',
        phone: '',
        gender: '',
        age: '',
        sampleNo: '',
        reports: [],
        productId: '',
        number: ''
      },
      // 添加历史报告表单验证
      rules: {
        name: [
          { required: true, message: '请输入用户姓名', trigger: 'change' },
          { min: 1, max: 30, message: '你名字有这么长吗？', trigger: 'blur' }
        ],
        number: [{ required: true, message: '请输入试管编号', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { min: 11, max: 11, message: '请输入11位数的手机号', trigger: 'blur' }
        ],
        sampleNo: [{ required: true, message: '请输入样本编号', trigger: 'change' }],
        productId: [{ required: true, message: '请选择产品应该产品', trigger: 'change' }],
        reports: [{ required: true, message: '请上传一个pdf文件', trigger: 'change' }]
      },
      rules1: {
        startTime: [{ type: 'date', required: true, message: '请选择开始日期', trigger: 'change' }],
        endTime: [{ type: 'date', required: true, message: '请选择结束日期', trigger: 'change' }]
      },
      // 上传文件/图片请求消息头
      headers: {
        token: sessionStorage.getItem('token')
      },
      productList: {
        pageNum: 1,
        pageSize: 100
      },
      downloadFormParameter: {
        orderIdList: [],
        startTime: '',
        endTime: ''
      }
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getOrderList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getOrderList()
    },
    // 状态改变时触发
    fileChange(uploadFile, fileList) {
      this.reportList.reports = fileList
      if (fileList.length !== 0) {
        this.$refs.reportList.validateField('reports')
      }
    },
    // 打开报告弹出框
    async openReportBox() {
      const { data: res } = await this.$http.get('/admin/product/getProductList', { params: this.productList })
      this.productIdList = res.data.list
      this.dialogVisible = true
    },
    // 移除时触发
    fileRemove(uploadFile, fileList) {
      if (fileList.length === 0) {
        this.reportList.reports = null
        this.$refs.reportList.validateField('reports')
      }
    },
    //文件上传失败触发
    uploadFalse(response, file, fileList) {
      this.$message({
        message: '文件上传失败！',
        type: 'error'
      })
    },
    // 上传文件/图片手动确定上传
    submitUpload(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false
          let fileFormData = new FormData()
          this.reportList.reports.forEach((f) => {
            fileFormData.append('reports', f.raw)
          })
          fileFormData.append('name', this.reportList.name)
          fileFormData.append('phone', this.reportList.phone)
          fileFormData.append('sampleNo', this.reportList.sampleNo)
          fileFormData.append('number', this.reportList.number)
          fileFormData.append('age', this.reportList.age)
          fileFormData.append('gender', this.reportList.gender)
          fileFormData.append('productId', this.reportList.productId)
          const { data: res } = await this.$http.post(`/admin/report/importReport`, fileFormData)
          if (res.code == 200) {
            this.$message.success('导入成功')
            this.$refs.upload.clearFiles()
            this.reportList = {}
            this.reportList.reports = []
            this.getOrderList()
          } else {
            this.$message.error(res.msg)
            this.$refs.upload.clearFiles()
            this.reportList = {}
            this.reportList.reports = []
          }
        } else {
          return false
        }
      })
    },
    // 判断类型和大小
    beforeAvatarUpload(file) {
      const isPDF = file.type === 'application/pdf' || file.type === 'application/PDF'
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isPDF) {
        this.$message.error('文件类型只能是PDF格式!')
      }
      if (!isLt20M) {
        this.$message.error('文件大小不能超过20MB!')
      }
      return isPDF && isLt20M
    },
    getAuthAttr() {
      var authReport = window.sessionStorage.getItem('auth-3')
      var authAttr = window.sessionStorage.getItem('auth-8')
      var authProduct = window.sessionStorage.getItem('auth-4')
      this.authReport = authReport
      this.authProduct = authProduct
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getOrderList() {
      const { data: res } = await this.$http.get('/admin/order/getOrderList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    //判断身份认证状态
    lx(row) {
      if (row.payType == 1) {
        return '支付宝'
      } else if (row.payType == 2) {
        return '微信'
      } else if (row.payType == 3) {
        return '银行卡'
      } else {
        return '现金'
      }
    },
    status(row) {
      if (row.status == 0) {
        return '未付款'
      } else if (row.status == 1) {
        return '已付款'
      } else {
        return '已退款'
      }
    },
    reportStatus(row) {
      if (row.reportStatus == 1) {
        return '未出报告'
      } else if (row.reportStatus == 2) {
        return '已出报告'
      } else if (row.reportStatus == 3) {
        return '已邮寄报告'
      } else {
        return '未采样'
      }
    },
    // 清除失效产品
    async productout() {
      const msg = await this.$confirm('你确定要清空所有失效且未付款订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/admin/order/delInvalidOrder`)
        if (res.code == 200) {
          return this.$message.success('清空成功！')
        } else {
          return this.$message.warning('清空失败')
        }
        //删除成功了，刷新页面
        // this.getXqTypeList()
      }
    },
    // 详情
    async delHandler(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, pageNum: this.queryParams.pageNum, particularsType: 1 } })
    },
    queryWarehouseHandle(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, type: 2 } })
    },
    // 下载订单返利表格
    async exportOrderRebateExcel() {
      const { data: res } = await this.$http.post('/admin/order/exportOrderRebateExcel', this.downloadFormParameter, { responseType: 'blob' })
      const blob = new Blob([res])
      let a = document.createElement('a')
      a.style.display = 'none'
      a.download = `订单返利.xlsx`
      a.href = URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.click()
    },
    submitDownload(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.downloaddialogVisible = false
          this.exportOrderRebateExcel()
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.pagination {
  padding-top: 10px;
  text-align: right;
}
</style>