<template>
  <div class="xqq">

    <el-form label-width="80px"
             :inline="true"
             ref="myform">
      <el-form-item label="手机号或姓名"
                    prop="keyword"
                    label-width="100px">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入手机号或姓名"
                  clearable />

      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="medium"
                   @click="getXqTypeList">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <template>
      <el-table align="center"
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="name"
                         align="center"
                         label="姓名"
                         width="180"></el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="手机"
                         width="180"></el-table-column>
        <el-table-column prop="age"
                         align="center"
                         label="年龄"
                         width="180"></el-table-column>
        <el-table-column prop="address"
                         align="center"
                         width="180"
                         label="地址"></el-table-column>
      </el-table>
    </template>

    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryParams.pageNum"
                       :page-sizes="[10, 20, 30, 50]"
                       :page-size="queryParams.pageSize"
                       layout="total, prev, pager, next"
                       :total="total">
        </el-pagination>
      </el-row>
    </template>

  </div>
</template>

<script>
export default {
  mounted() {
    this.getXqTypeList()
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      tableData: [],
      total: 0,
      editDisabled: true,
      delDisabled: true
    }
  },
  methods: {
    // 查询表格数据
    async getXqTypeList() {
      const { data: res } = await this.$http.get('/admin/seller/getUserListBySeller', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },

    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getXqTypeList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getXqTypeList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>