<!-- 预约模块 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入姓名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item label="预约号"
                    label-width="68px">
        <el-input size="medium"
                  v-model="queryParams.id"
                  clearable
                  placeholder="请输入预约号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>

    </el-form>

    <!-- 数据表格 -->
    <template>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="id"
                         align="center"
                         show-overflow-tooltip
                         label="预约号"></el-table-column>
        <el-table-column prop="orderId"
                         align="center"
                         show-overflow-tooltip
                         label="订单id"></el-table-column>
        <el-table-column prop="name"
                         align="center"
                         label="联系人"
                         width="100"></el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         show-overflow-tooltip
                         label="联系电话"></el-table-column>
        <el-table-column prop="status"
                         align="center"
                         :formatter="zt"
                         label="状态"></el-table-column>
        <el-table-column prop="doorTime"
                         align="center"
                         show-overflow-tooltip
                         label="上门时间">
          <template slot-scope="scope">
            <span>{{scope.row.doorTime| datefmt('YYYY-MM-DD HH:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="doorTime2"
                         align="center"
                         show-overflow-tooltip
                         label="上门时间2">
          <template slot-scope="scope">
            <span>{{scope.row.doorTime2| datefmt('YYYY-MM-DD HH:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template v-slot="{row}">
            <el-button-group>
              <el-button type="default"
                         size="mini"
                         icon="el-icon-tickets"
                         @click="delHandler(row.id)">详情</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    layout="total, prev, pager, next"
                    @pagination="getAppointmentListBySeller" />
      </el-row>
    </template>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      //添加客户预约
      recompose: {
        phone: '',
        name: '',
        address: '',
        doorTime: '',
        doorTime2: '',
        remarks: '',
        orderId: ''
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        id: ''
      },
      tableData: [],
      total: 0,
      addDialogVisible: false,
      continueAddFlag: false,
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        orderId: [{ required: true, message: '请输入订单号', trigger: 'blur' }],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        doorTime: [{ type: 'date', required: true, message: '请选择上门时间', trigger: 'change' }],
        doorTime2: [{ type: 'date', required: true, message: '请选择上门时间2', trigger: 'change' }]
      }
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getAppointmentListBySeller()
  },

  methods: {
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.recompose.doorTime = this.dayjs(this.recompose.doorTime).format('YYYY-MM-DD HH:mm:ss')
          this.recompose.doorTime2 = this.dayjs(this.recompose.doorTime2).format('YYYY-MM-DD HH:mm:ss')
          const { data: res } = await this.$http.post('/admin/seller/addAppointmentBySeller', this.recompose)
          console.log(res)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getAppointmentListBySeller()
    },
    dateFormat(row, column) {
      var dT = new Date(row.doorTime) //row 表示一行数据, dateTime 表示要格式化的字段名称
      return dT.getFullYear() + '-' + (dT.getMonth() + 1) + '-' + dT.getDate() + ' ' + dT.getHours() + ':' + dT.getMinutes() + ':' + dT.getSeconds()
    },
    // 查询表格数据
    async getAppointmentListBySeller() {
      const { data: res } = await this.$http.get('/admin/seller/getAppointmentListBySeller', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    zt(row) {
      if (row.status == 0) {
        return '已取消'
      } else if (row.status == 1) {
        return '已预约'
      } else if (row.status == 2) {
        return '已确认'
      } else if (row.status == 3) {
        return '已完成'
      }
    },
    typeFormat(row) {
      if (row.type == 1) {
        return '血液'
      } else if (row.type == 2) {
        return '尿液'
      } else if (row.type == 3) {
        return '唾液'
      } else {
        return '未知'
      }
    },
    // 详情
    async delHandler(id) {
      this.$router.push({ path: 'cancelTheReservation', query: { id: id, pageNum: this.queryParams.pageNum } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>