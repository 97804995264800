<template>
  <!-- 角色管理页面 -->
  <div>
    <!-- 表格 -->
    <el-table :data="roleList"
              align="center"
              style="width: 100%"
              max-height="520"
              :header-cell-style="{background: '#f8f8f9'}">
      <el-table-column prop="role"
                       align="center"
                       :show-overflow-tooltip="true"
                       label="身份">
        <template slot-scope="scope">
          <span v-show="scope.row.role=='ROLE_ADMIN_PRODUCT'">产品管理员</span>
          <span v-show="scope.row.role=='ROLE_ADMIN_SELLER'">销售管理员</span>
          <span v-show="scope.row.role=='ROLE_SELLER'">销售</span>

        </template>
      </el-table-column>
      <el-table-column prop="status"
                       align="center"
                       label="状态"
                       :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     :active-value="0"
                     :inactive-value="1"
                     active-text="启用"
                     inactive-text="禁用"
                     @change="updateBrandStatus(scope.row)"
                     v-if="scope.row.status!=null"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页导航 -->

  </div>
</template >

<script>
export default {
  data() {
    return {
      roleList: []
    }
  },
  mounted() {
    this.getIdentityList()
  },
  methods: {
    async getIdentityList() {
      const { data: res } = await this.$http.get('/admin/admin/getIdentityList')
      this.roleList = res.data
    },
    async updateBrandStatus(row) {
      const { data: res } = await this.$http.post(`/admin/admin/setIdentityStatus?id=${row.id}&status=${row.status}`)
      this.getIdentityList()
    }
  }
}
</script>

<style>
</style>