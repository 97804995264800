<!-- 产品模块 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form ref="myform"
             size="small"
             :inline="true"
             label-width="70px">
      <el-form-item label="状态">
        <el-select size="medium"
                   v-model="queryParams.status"
                   placeholder="请选择状态"
                   clearable>
          <el-option label="上架"
                     value="0"></el-option>
          <el-option label="下架"
                     value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button v-if="authAttr>1"
                   @click="addDialogVisible=true"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加产品</el-button>
      </el-form-item>

    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="name"
                           align="center"
                           label="产品名"
                           width="180"></el-table-column>
          <el-table-column prop="shortName"
                           align="center"
                           label="产品名缩写"
                           width="160"></el-table-column>
          <el-table-column prop="shortIntroduce"
                           align="center"
                           label="产品简介"
                           width="180"></el-table-column>

          <el-table-column prop="cover"
                           align="center"
                           label="封面"
                           width="120">
            <template slot-scope="scope">
              <el-image v-if="scope.row.cover"
                        style="width: 100px; height: 100px"
                        :preview-src-list="srcList"
                        :src="$url+scope.row.cover">
                <div slot="error"
                     class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="price"
                           align="center"
                           label="价格"
                           width="100"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           width="150"
                           label="状态">
            <template slot-scope="scope">
              <span v-if="authAttr<=1">
                <span v-if="scope.row.status==0">已上架</span>
                <span v-if="scope.row.status==1">已下架</span>
              </span>
              <el-switch v-if="authAttr>1"
                         v-model="scope.row.status"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         :active-value="0"
                         :inactive-value="1"
                         active-text="上架"
                         inactive-text="下架"
                         @change="updateBrandStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="180">
            <template v-slot="scope">
              <el-button type="text"
                         icon="el-icon-tickets"
                         @click="updateHandler(scope.row.id)">详情</el-button>
            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getProductList" />
        </el-row>
      </template>
    </el-card>
    <!-- 添加产品对话框 -->
    <template>
      <el-dialog title="添加产品"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="产品名称"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入产品名称（必填）"></el-input>
            </el-form-item>
            <el-form-item label="产品简称"
                          prop="shortName">
              <el-input v-model="recompose.shortName"
                        placeholder="请输入产品简称"></el-input>
            </el-form-item>
            <el-form-item label="简介"
                          prop="shortIntroduce">
              <el-input v-model="recompose.shortIntroduce"
                        placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select size="medium"
                         v-model="recompose.status"
                         placeholder="请输入状态">
                <el-option label="上架"
                           value="0"></el-option>
                <el-option label="下架"
                           value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="介绍">
              <el-input type="textarea"
                        v-model="recompose.introduce"></el-input>
              <!-- <ckeditor v-model="recompose.introduce"
                        :config="editorConfig"></ckeditor> -->

              <!-- <Editor v-model="recompose.introduce"
                      :value="recompose.introduce"></Editor> -->
            </el-form-item>
            <el-form-item label="价格"
                          prop="price">
              <el-input type="number"
                        v-model="recompose.price"
                        placeholder="请输入价格:单位元（必填）"></el-input>
            </el-form-item>
            <el-form-item label="类型"
                          prop="type">
              <el-input v-model="recompose.type"
                        placeholder="请输入类型"></el-input>
            </el-form-item>
            <el-form-item label="图片文件"
                          prop="images">
              <el-upload action="/api/admin/product/addProduct"
                         ref="upload"
                         :headers="headers"
                         multiple
                         :data='recompose'
                         :auto-upload="false"
                         :file-list="fileList"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="封面"
                          prop="cover">
              <el-upload action="/api/admin/product/addProduct"
                         ref="upload2"
                         :headers="headers"
                         :limit="1"
                         :data='recompose'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-remove="handleRemoves"
                         :on-change="handleChange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
import Pagination from '@/components/Pagination'
export default {
  components: {
    ckeditor: CKEditor.component,
    Pagination
  },
  data() {
    return {
      // 富文本编辑器的配置
      editorConfig: {},
      headers: {
        token: sessionStorage.getItem('token')
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: ''
      },
      // 表格数据
      tableData: [],
      // 分类下拉列表数据
      total: 0,
      addDialogVisible: false,
      alterProduct: false,

      //添加产品信息
      recompose: {
        shortName: '',
        name: '',
        shortIntroduce: '',
        status: '0',
        introduce: '',
        price: '',
        type: '',
        cover: null,
        images: null
      },
      authAttr: '',
      fileList: [],
      cover: '',
      formData: new FormData(),
      productId: '',
      continueAddFlag: false,
      rules: {
        name: [
          { required: true, message: '请输入产品名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1到 50 个字符', trigger: 'blur' }
        ],
        shortName: [{ min: 1, max: 20, message: '长度在 1到 20 个字符', trigger: 'blur' }],
        shortIntroduce: [{ min: 0, max: 100, message: '长度在 0到 100 个字符', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'change' }],
        images: [{ required: true, message: '请上传图片文件', trigger: 'change' }],
        cover: [{ required: true, message: '请上传封面', trigger: 'change' }]
      },
      hideCode: false,
      limitCount: 1,
      limitCode: 1,
      codeFile: '',
      codeFileList: '',
      srcList: []
    }
  },
  mounted() {
    this.getProductList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getProductList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-4')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getProductList() {
      const { data: res } = await this.$http.get('/admin/product/getProductList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.srcList = []
      res.data.list.forEach((e) => {
        if (e.cover != null && e.cover != '') {
          this.srcList.push(this.$url + e.cover)
        }
      })
      this.total = res.data.total
    },
    hanlechange(file, fileList) {
      this.fileList = fileList
      this.fileList.forEach((f) => {
        this.formData.set(f.name, f.raw)
      })

      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { images, ...data } = this.rules
        this.rules = data
      }
      this.$refs.recompose.clearValidate('images')
      this.hideCode = fileList.length >= this.limitCode
    },
    handleChange(file, fileList) {
      this.cover = file.raw
      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { cover, ...data } = this.rules
        this.rules = data
      }
      this.$refs.recompose.clearValidate('cover')
      this.hideCode = fileList.length >= this.limitCode
    },

    // 详情
    async updateHandler(_id) {
      this.$router.push({ path: 'productParticulars', query: { id: _id } })
    },
    // 修改状态上架下架
    async updateBrandStatus(row) {
      const { data: res } = await this.$http.post(`/admin/product/setProductStatus?id=${row.id}&status=${row.status}`)
      this.getProductList()
    },
    // 添加产品提交
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let fileFormData = new FormData()
          this.fileList.forEach((f) => {
            fileFormData.append('images', f.raw)
          })
          fileFormData.append('cover', this.cover)
          fileFormData.append('name', this.recompose.name)
          fileFormData.append('shortName', this.recompose.shortName)
          fileFormData.append('shortIntroduce', this.recompose.shortIntroduce)
          fileFormData.append('introduce', this.recompose.introduce)
          fileFormData.append('status', this.recompose.status)
          fileFormData.append('price', this.recompose.price)
          fileFormData.append('type', this.recompose.type)
          if (!this.continueAddFlag) {
            this.addDialogVisible = false
          }
          this.recompose = {}
          this.rules.images = [{ required: true, message: '请上传图片文件', trigger: 'change' }]
          this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
          const { data: res } = await this.$http.post(`/admin/product/addProduct`, fileFormData)
          console.log(res)
          if (res.code == 200) {
            this.$message.success('添加成功')
            this.$refs.upload.clearFiles()
            this.$refs.upload2.clearFiles()
            this.cover = ''
            this.fileList = []
          } else {
            this.$message.error('添加失败')
            this.$refs.upload.clearFiles()
            this.$refs.upload2.clearFiles()
            this.cover = ''
            this.fileList = []
          }
          this.getProductList()
        }
      })
    },
    handleRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.images = [{ required: true, message: '请上传图片文件', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleRemoves(file, fileList) {
      if (fileList.length == 0) {
        this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    }
  }
}
</script>
<style lang='scss' scoped>
.pagination {
  padding-top: 10px;
  text-align: right;
}
.el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
</style>
