<!-- 返利详情 -->
<template>
  <div v-loading.fullscreen.lock="loading"
       element-loading-text=" 报告上传中...">
    <el-card>
      <div class="headline">订单返利信息</div>
      <el-table :data="ordersList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="role"
                         align="center"
                         :formatter="role"
                         label="角色"></el-table-column>
        <el-table-column prop="type"
                         align="center"
                         :formatter="type"
                         label="类型"></el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="用户">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click.native="goAgencyInfo(scope.row.phone)">{{textFilter(scope.row.phone,scope.row.name)}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="commission"
                         align="center"
                         label="积金"></el-table-column>
        <el-table-column prop="profit"
                         align="center"
                         label="收益"></el-table-column>

        <el-table-column prop="createTime"
                         align="center"
                         label="时间"></el-table-column>

      </el-table>
    </el-card>

  </div>
</template>

<script>
export default {
  name: 'Orders',
  data() {
    return {
      orderid: '',
      ordersList: [],
      loading: false
    }
  },
  mounted() {
    this.orderid = this.$route.query.orderId
    this.getRebateInfoByOrderId()
  },

  methods: {
    async getRebateInfoByOrderId() {
      const { data: res } = await this.$http.get(`/admin/commission_record/getRebateInfoByOrderId?orderId=${this.orderid}`)
      if (res.code == 200) {
        this.ordersList = res.data
      }
    },
    // 角色
    role(row) {
      if (row.role == 0) {
        return '特级代理'
      } else if (row.role == 1) {
        return '市级代理'
      } else if (row.role == 2) {
        return '区县代理'
      } else if (row.role == 3) {
        return '个人代理'
      }
    },
    // 类型
    type(row) {
      if (row.type == 1) {
        return '订单返利'
      } else if (row.type == 2) {
        return '居间费'
      } else if (row.type == 3) {
        return '其他'
      } else if (row.type == 4) {
        return '特级居间费'
      }
    },
    // 跳转代理详情
    async goAgencyInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, type: 5 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.headline {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
.basicsHeadline {
  font-size: 15px;
  margin: 10px 0px;
  font-weight: 600;
}
.elseHeadline {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}
.link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>