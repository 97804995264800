<!-- 用户资产手机号重定向7 -->
<template>
  <div class="dtgl">

  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    // 重定向（）
    this.$router.replace(this.$route.query.router)
  }
  //
}
</script>
<style lang='scss' scoped>
</style>