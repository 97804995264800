<!-- 用户信息 -->
<template>
  <div>
    <div>
      <i class="el-icon-arrow-left"
         style="color:#5aabff; font-weight:800"></i>
      <el-button @click="back"
                 type="text"
                 size="small"
                 style="font-weight:800">返回</el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户信息"
                   v-if="userAuth"
                   name="tab1">
        <template>
          <el-card class="box-card">
            <!-- 订单详情 -->
            <el-descriptions :column=5>
              <el-descriptions-item label="头像"
                                    v-if="form.avatar">
                <img style="width: 30px; height: 30px;"
                     :Src=$url+form.avatar></img>
              </el-descriptions-item>
              <el-descriptions-item label="姓名">{{form.name}}</el-descriptions-item>
              <el-descriptions-item label="年龄">{{form.age}}</el-descriptions-item>
              <el-descriptions-item label="手机号">{{form.phone}}</el-descriptions-item>
              <el-descriptions-item label="性别">{{form.gender==0?'男':'女'}}</el-descriptions-item>
              <el-descriptions-item label="状态">{{form.status==0?'未注册':form.status==1?'已注册':form.status==2?'已禁用':'已注销'}}</el-descriptions-item>
              <el-descriptions-item label="注册时间">{{form.createdTime}}</el-descriptions-item>
              <el-descriptions-item label="渠道名">{{form.sellerName}}</el-descriptions-item>
              <el-descriptions-item label="积分">{{form.score}}</el-descriptions-item>
              <el-descriptions-item label="轻子">{{form.lepton}}</el-descriptions-item>
              <el-descriptions-item label="夸克">{{form.quark}}</el-descriptions-item>
            </el-descriptions>
            <el-button v-if="(form.status==1||form.status==2)&&authAttr>2"
                       type="danger"
                       size="small"
                       icon="el-icon-delete"
                       @click="delHandler()">注销用户</el-button>
          </el-card>
        </template>
      </el-tab-pane>
      <el-tab-pane label="用户订单"
                   v-if="authAttr"
                   name="tab2"><template>
          <UserOrderInfo :phone="phone" />
        </template>
      </el-tab-pane>
      <el-tab-pane label="用户资产"
                   v-if="propertyAuth"
                   name="tab3"><template>
          <UserRecordParticulars :phone=queryParams.phone
                                 :type=type />
        </template>
      </el-tab-pane>
      <el-tab-pane label="用户预约"
                   v-if="appointmentAuth"
                   name="tab4">
        <UserAppointmentInfo :phone=queryParams.phone />
      </el-tab-pane>
      <el-tab-pane v-if="agencyAuth"
                   label="用户代理"
                   name="tab5">
        <userAgencyVue :phone=phone
                       :type=type />
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import UserRecordParticulars from './userRecordParticulars.vue'
import UserAppointmentParticulars from './userAppointmentParticulars.vue'
import UserAppointmentInfo from './userAppointmentInfo.vue'
import UserOrderInfo from './userOrderInfo.vue'
import userAgencyVue from './userAgency.vue'
export default {
  components: {
    UserRecordParticulars,
    UserAppointmentParticulars,
    UserAppointmentInfo,
    UserOrderInfo,
    userAgencyVue
  },
  data() {
    return {
      activeName: '1',
      authAttr: '',
      propertyAuth: '',
      agencyAuth: '',
      appointmentAuth: '',
      userAuth: '',
      userPhone: '',
      form: {},
      uploadFlag: false,
      headers: {
        token: sessionStorage.getItem('token')
      },
      action: '',
      phone: '',
      // 表格
      tableData: [],
      pageNum: '',
      type: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0',
        phone: ''
      },
      tab: ''
    }
  },
  created() {
    this.tab = this.$route.query.tab
    this.phone = this.$route.query.phone
    this.queryParams.phone = this.$route.query.phone
    this.pageNum = this.$route.query.pageNum
    this.type = this.$route.query.type
    this.activeName = this.$route.query.type
    if (this.tab) {
      this.activeName = 'tab' + this.tab
    } else {
      this.activeName = 'tab' + this.type
    }
    this.getAuthAttr()
    if (this.userAuth) {
      this.getUserInfo()
    }
  },
  methods: {
    // 获取权限
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      var propertyAuth = window.sessionStorage.getItem('auth-11')
      var agencyAuth = window.sessionStorage.getItem('auth-14')
      var appointmentAuth = window.sessionStorage.getItem('auth-7')
      var userAuth = window.sessionStorage.getItem('auth-2')
      this.authAttr = authAttr
      this.propertyAuth = propertyAuth
      this.agencyAuth = agencyAuth
      this.userAuth = userAuth
      this.appointmentAuth = appointmentAuth
    },
    // 详情
    async getUserInfo() {
      const { data: res } = await this.$http.get(`/admin/user/getUserInfo?phone=${this.phone}`)
      if (res.code == 200) {
        this.tableData = res.data.orderInfoListRes
        this.form = res.data
        this.form.reportStatus = res.data.reportStatus == null ? '' : res.data.reportStatus + ''
      } else {
        this.$message.error(res.msg)
      }
    },

    async delHandler() {
      const msg = await this.$confirm('注销会清空用户的所有数据，您确定要注销吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.post(`/admin/user/cancelUser/?id=${this.form.id}`)
        if (res.code != 200) {
          return this.$message.error(res.msg)
        } else {
          this.$message.success('注销成功')
        }
        //删除成功了，刷新页面
        this.getUserInfo()
      }
    },
    // 返回用户列表
    back() {
      this.$router.go(-1)
      // if (!this.pageNum) {
      //   this.$router.back()
      // } else {
      //   var pathArr = ['/user', '/order', '/recommend/record', '/appointment', '/agency']
      //   var path = pathArr[this.type - 1]
      //   this.$router.push({ path: path, query: { pageNum: this.pageNum } })
      // }
    }
  },
  filters: {
    filterStatus: function (status) {
      if (status == 0) {
        return '未注册'
      } else if (status == 1) {
        return '正常'
      } else if (status == 2) {
        return '已锁定'
      } else if (status == 3) {
        return '已注销'
      } else return ''
    }
  }
}
</script>
<style lang='scss' scoped>
</style>