<!-- 代理详情 -->
<template>
  <div>
    <template>
      <!-- 变更用户收益对话框 -->
      <el-dialog title="变更用户收益"
                 :visible.sync="changeCommissionDialogue"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="form"
                   :model="changeCommissionList"
                   label-width="90px">
            <el-form-item label="返利">
              <el-input-number v-model="changeCommissionList.commission"
                               :step="2"></el-input-number>
            </el-form-item>
            <el-form-item label="收益">
              <el-input-number v-model="changeCommissionList.profit"
                               :step="2"></el-input-number>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="changeCommissionList.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeCommissionDialogue = false">取 消</el-button>
          <el-button type="success"
                     @click="changeCommission()">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <el-card class="box-card">
      <el-descriptions title="代理信息">
        <template slot="extra"
                  v-if="agencyAuth>1">
          <el-button type="primary"
                     size="small"
                     @click="openAgencyDialog">编辑代理</el-button>
        </template>
        <el-descriptions-item label="用户">{{textFilter(proxyInfoList.phone,proxyInfoList.username)}}</el-descriptions-item>
        <el-descriptions-item label="代理角色"
                              v-if="proxyInfoList.proxyRole!=null">
          <el-tag size="small">{{proxyInfoList.proxyRole==1?'市级代理':proxyInfoList.proxyRole==2?'区县代理':proxyInfoList.proxyRole==0?'特级代理':'个人代理'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="上级代理"
                              v-if="proxyInfoList.topPhone">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.topPhone)"> {{textFilter(proxyInfoList.topPhone,proxyInfoList.topName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="推荐人"
                              v-if="proxyInfoList.recommenderPhone">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.recommenderPhone)">{{textFilter(proxyInfoList.recommenderPhone,proxyInfoList.recommenderName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="居间人">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.intermediaryPhone)">{{textFilter(proxyInfoList.intermediaryPhone,proxyInfoList.intermediaryName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="代理创建时间">{{proxyInfoList.createTime}}</el-descriptions-item>
        <el-descriptions-item label="返利">{{proxyInfoList.commission}}</el-descriptions-item>
        <el-descriptions-item label="收益">{{proxyInfoList.profit}}</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <template>
      <!-- 编辑代理对话框 -->
      <el-dialog title="编辑代理"
                 :visible.sync="changeAgencyInfoDialogue"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="showUpdateUserRecommenderObj"
                   ref="updateUserRecommenderObj"
                   label-width="100px">
            <el-form-item label="推荐人手机号"
                          prop="userId">
              <el-select v-model="showUpdateUserRecommenderObj.recommender"
                         @change="changeRecommender($event)"
                         clearable
                         filterable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 4)"
                         placeholder="请输入用户手机号">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeAgencyInfoDialogue = false">取 消</el-button>
          <el-button type="success"
                     @click="updateUserRecommender()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <div class="head">
      <h1 class="title">下级代理</h1>
    </div>
    <el-card class="box-card">
      <el-table :data="proxyTreeList"
                style="width: 100%"
                row-key="phone"
                border
                :tree-props="{children: 'subProxyResList'}">
        <el-table-column prop="phone"
                         label="手机号"
                         width="180">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click.native="goto(scope.row.phone)">{{scope.row.phone}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="姓名"
                         width="180">
        </el-table-column>
        <el-table-column prop="saleCount"
                         label="销售量"
                         width="180">
        </el-table-column>
        <el-table-column prop="proxyRole"
                         label="代理角色"
                         :formatter="identity"
                         width="180">
        </el-table-column>

      </el-table>
    </el-card>
    <div class="head">
      <h1 class="title">推荐用户</h1>
    </div>
    <el-card class="box-card">
      <el-table :data="recommenderUserList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="name"
                         align="center"
                         label="姓名">
        </el-table-column>

        <el-table-column prop="phone"
                         align="center"
                         label="手机号"></el-table-column>
        <el-table-column prop="orderCount"
                         align="center"
                         label="下单数量"></el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="创建时间"></el-table-column>

      </el-table>
    </el-card>
    <div class="head">
      <h1 class="title">变更记录</h1>
      <div class="but">
        <el-button v-if="agencyAuth>1"
                   type="primary"
                   size="medium"
                   @click="changeCommissionDialogue=true">变更用户收益</el-button>
      </div>
    </div>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="commissionRecordList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="commission"
                           align="center"
                           label="返利">
          </el-table-column>
          <el-table-column prop="profit"
                           align="center"
                           label="收益"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="recommendedPhone"
                           align="center"
                           label="下单用户"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goto(scope.row.recommendedPhone)">{{textFilter(scope.row.recommendedPhone,scope.row.recommendedName)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="recommenderPhone"
                           align="center"
                           label="推荐人"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goto(scope.row.recommenderPhone)">{{textFilter(scope.row.recommenderPhone,scope.row.recommenderName)}}</el-link>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="productName"
                           align="center"
                           label="产品"
                           show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="type"
                           align="center"
                           :formatter="typeFormatter"
                           label="类型"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderId"
                           align="center"
                           show-overflow-tooltip
                           label="订单号">
            <template slot-scope="scope"
                      v-if="orderAttr>=1">
              <el-link type="primary"
                       :underline="false"
                       @click.native="gotoOrderld(scope.row.orderId,)">{{scope.row.orderId}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="remarks"
                           align="center"
                           show-overflow-tooltip
                           label="备注">
          </el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="时间"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.createTime| datefmt('YYYY-MM-DD HH:mm')}}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页导航 -->
        <template>
          <el-row class="pagination">
            <pagination v-show="total>0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        @pagination="getCommissionRecordList" />
          </el-row>
        </template>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'UserAgency',
  components: {
    Pagination
  },
  props: {
    phone: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: ''
      },
      commissionRecordList: [],
      proxyInfoList: {},
      changeCommissionList: {
        phone: '',
        remarks: '',
        commission: '',
        profit: ''
      },
      total: 0,
      changeCommissionDialogue: false,
      changeAgencyInfoDialogue: false,
      agencyAuth: '',
      orderAttr: '',
      proxyTreeList: [],
      recommenderUserList: [],
      updateUserRecommenderObj: {
        phone: '',
        recommender: ''
      },
      options: [],
      showUpdateUserRecommenderObj: {
        recommender: ''
      }
    }
  },
  watch: {
    // 路由发生变化刷新整个组件
    $route() {
      window.location.reload()
    }
  },
  mounted() {
    this.queryParams.phone = this.phone
    this.changeCommissionList.phone = this.phone
    this.getProxyInfo()
    this.getCommissionRecordList()
    this.getAuthAttr()
    this.getProxyTree()
    this.getRecommenderUserList()
  },

  methods: {
    changeRecommender(e) {
      this.updateUserRecommenderObj.recommender = e
    },
    // 打开编辑代理对话框
    openAgencyDialog() {
      this.changeAgencyInfoDialogue = true
      if (this.proxyInfoList.recommenderPhone) {
        this.updateUserRecommenderObj.recommender = this.proxyInfoList.recommender
        this.showUpdateUserRecommenderObj.recommender = this.proxyInfoList.recommenderPhone
        if (this.proxyInfoList.recommenderName && this.proxyInfoList.recommenderName != null) {
          this.showUpdateUserRecommenderObj.recommender = this.proxyInfoList.recommenderPhone + '_' + this.proxyInfoList.recommenderName
        }
      }
    },
    userJumpChange() {
      this.options = []
    },
    // 获取代理可添加人员
    async getAddableUser(keyword, type) {
      const { data: res } = await this.$http.get('/admin/proxy/getAddableUser', { params: { keyword: keyword, type: type } })
      if (res.code == 200) {
        this.options = []
        res.data.forEach((element) => {
          var obj = {}
          obj['label'] = `${element.phone}${element.name == null ? '' : '(' + element.name + ')'}`
          obj['id'] = element.id
          this.options.push(obj)
        })
      }
    },
    // 修改用户推荐人
    async updateUserRecommender() {
      this.updateUserRecommenderObj.phone = this.proxyInfoList.phone
      const { data: res } = await this.$http.post(`/admin/proxy/updateUserRecommender`, this.updateUserRecommenderObj)
      if (res.code == 200) {
        this.$message.success('修改推荐人成功')
        this.getProxyInfo()
        this.changeAgencyInfoDialogue = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 查询下级代理
    async getProxyTree() {
      const { data: res } = await this.$http.get(`/admin/proxy/getProxyTree?phone=${this.queryParams.phone}`)
      this.proxyTreeList = res.data
    },
    // 获取推荐用户
    async getRecommenderUserList() {
      const { data: res } = await this.$http.get(`/admin/proxy/getRecommenderUserList?phone=${this.queryParams.phone}`)

      this.recommenderUserList = res.data
    },
    getAuthAttr() {
      var agencyAuth = window.sessionStorage.getItem('auth-14')
      var orderAttr = window.sessionStorage.getItem('auth-3')
      this.agencyAuth = agencyAuth
      this.orderAttr = orderAttr
    },
    async getProxyInfo() {
      const { data: res } = await this.$http.get(`/admin/proxy/getProxyInfo?phone=${this.queryParams.phone}`)
      this.proxyInfoList = res.data
    },
    async getCommissionRecordList() {
      const { data: res } = await this.$http.get('/admin/commission_record/getCommissionRecordList', { params: this.queryParams })
      this.commissionRecordList = res.data.list
      this.total = res.data.total
    },
    async changeCommission() {
      const { data: res } = await this.$http.post('/admin/commission_record/changeCommission', this.changeCommissionList)
      if (res.code == 200) {
        this.$message.success('变更用户收益成功')
        this.getProxyInfo()
        this.getCommissionRecordList()
      } else {
        this.$message.error(res.msg)
      }
      this.changeCommissionDialogue = false
      this.changeCommissionList.commission = ''
      this.changeCommissionList.profit = ''
    },
    identity(row) {
      if (row.proxyRole == 1) {
        return '市级代理'
      } else if (row.proxyRole == 2) {
        return '区县代理'
      } else if (row.proxyRole == 3) {
        return '个人代理'
      } else if (row.proxyRole == 0) {
        return '特级代理'
      }
    },
    typeFormatter(row) {
      if (row.type == 1) {
        return '订单返利'
      } else if (row.type == 2) {
        return '居间费'
      } else if (row.type == 3) {
        return '后台操作'
      } else if (row.type == 4) {
        return '特级居间费'
      } else if (row.type == 5) {
        return '提现'
      }
    },

    goto(phone) {
      this.$router.push({ path: '/redirect', query: { router: `/userInfo?phone=${phone}&type=${this.type}&tab=5` } })
    },
    gotoOrderld(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, pageNum: this.queryParams.pageNum, particularsType: 4 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.head {
  display: flex;
  height: 50px;
}
.title {
  margin: auto 0;
}
.but {
  margin: auto 10px;
}
</style>