<!-- 预约详情 -->
<template>
  <div>
    <el-form ref="form"
             :model="xqlist"
             label-width="90px">
      <el-form-item label="预约号"
                    prop="id">
        <el-col :span="8">
          <el-input v-model="xqlist.id"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="联系人"
                    prop="number">
        <el-col :span="8">
          <el-input v-model="xqlist.name"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="状态">
        <el-col :span="8">
          <el-input :value="statusFormat(xqlist.status)"
                    :disabled="true">
          </el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="上门时间1"
                    prop="shortName">
        <el-col :span="8">
          <el-input v-model="xqlist.doorTime"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="上门时间2"
                    prop="shortName">
        <el-col :span="8">
          <el-input v-model="xqlist.doorTime2"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="联系电话"
                    prop="shortIntroduce">
        <el-col :span="8">
          <el-input v-model="xqlist.phone"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="地址"
                    prop="introduce">
        <el-col :span="14">
          <el-input v-model="xqlist.address"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="销售姓名">
        <el-col :span="8">
          <el-input v-model="xqlist.sellerName"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="确认上门时间"
                    label-width="170">
        <el-col :span="8">
          <el-input v-model="xqlist.checkTime"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="预约用户手机"
                    label-width="170"
                    prop="userPhone">
        <el-col :span="8">
          <el-input v-model="xqlist.userPhone"
                    :disabled="true" />
        </el-col>
      </el-form-item>
      <el-form-item label="备注"
                    prop="price">
        <el-input v-model="xqlist.remarks"
                  :disabled="true" />
      </el-form-item>
      <el-form-item label="取消原因"
                    prop="price">
        <el-input v-model="xqlist.cancelReason"
                  :disabled="true" />
      </el-form-item>
      <el-button @click="back"
                 type="success">返回预约列表</el-button>
      <el-button v-if="xqlist.status==1"
                 type="primary"
                 @click="modificationTime(2)">确认预约时间</el-button>
      <el-button v-if="xqlist.status==2"
                 type="primary"
                 @click="modificationTime(3)">确认已上门</el-button>
      <el-button v-if="xqlist.status==1||xqlist.status==2"
                 type="primary"
                 @click="openAppointment()">修改预约</el-button>
      <el-button v-if="xqlist.status==1||xqlist.status==2"
                 type="warning"
                 @click="modificationTime(0)">取消预约</el-button>
      <el-button v-if="authAttr>2"
                 type="danger"
                 icon="el-icon-delete"
                 @click="delAppointment()">删除预约</el-button>
    </el-form>
    <template>
      <el-dialog title="确认预约时间"
                 :visible.sync="affirm"
                 width="50%">
        <div class="demo-input-suffix">
          <el-radio-group v-model="radioTreaty"
                          @change="agreeChange">
            <el-radio v-model="radio"
                      :label=xqlist.doorTime
                      border>{{xqlist.doorTime}}</el-radio>
            <el-radio v-model="radio"
                      :label=xqlist.doorTime2
                      border>{{xqlist.doorTime2}}</el-radio>
            <el-radio v-if="xqlist.doorTime3"
                      v-model="radio"
                      :label=xqlist.doorTime3
                      border>{{xqlist.doorTime3}}</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="affirm = false">取 消</el-button>
          <el-button type="success"
                     @click="modifyAppointmentStatus()"
                     submit>提交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="取消预约时间"
                 :visible.sync="cancelTime"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="form"
                   :model="formData"
                   label-width="80px">
            <el-form-item label="取消原因">
              <el-input v-model="formData.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="cancelTime = false">取 消</el-button>
          <el-button type="success"
                     @click="alterState()"
                     submit>提交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="修改预约"
                 :visible.sync="alterProduct"
                 width="600px">
        <el-form ref="form"
                 :model="xqlist"
                 label-width="100px">
          <el-form-item label="联系人">
            <el-input v-model="xqlist.name"
                      placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="xqlist.phone"
                      placeholder="请输入联系电话" />
          </el-form-item>
          <el-form-item label="联系地址">
            <el-input v-model="xqlist.address"
                      placeholder="请输入联系地址" />
          </el-form-item>
          <el-form-item label="上门时间"
                        required>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date"
                                placeholder="选择日期"
                                v-model="recompose.date1"
                                :picker-options="pickerOptions"
                                style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line"
                    :span="2">——</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-time-select placeholder="选择时间"
                                v-model="recompose.time1"
                                :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="上门时间2"
                        required>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                v-model="recompose.date2"
                                style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line"
                    :span="2">——</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-time-select placeholder="选择时间"
                                v-model="recompose.time2"
                                :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="xqlist.remarks"
                      placeholder="请输入备注信息" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProduct = false">取 消</el-button>
          <el-button type="primary"
                     @click="modifyAppointment">提 交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'AppointmentParticulars',
  data() {
    return {
      authAttr: '',
      radioTreaty: '1',
      radio: '1',
      pageNum: '',
      xqlist: {},
      appointmentid: '',
      affirm: false,
      cancelTime: false,
      alterProduct: false,
      formData: {
        id: '',
        status: '',
        remarks: '',
        checkTime: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      recompose: {
        time2: '',
        time1: '',
        date: '',
        date2: ''
      }
    }
  },
  mounted() {
    this.pageNum = this.$route.query.pageNum
    this.appointmentid = this.$route.query.orderId
    this.getAuthAttr()
    this.getAppointmentInfo()
  },

  methods: {
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-7')
      this.authAttr = authAttr
    },
    agreeChange(val) {
      this.formData.checkTime = val
    },
    modificationTime(status) {
      if (status == 0) {
        this.cancelTime = true
        this.formData.status = status
        this.formData.id = this.xqlist.id
      } else if (status == 3) {
        this.formData.status = status
        this.formData.id = this.xqlist.id
        this.modifyAppointmentStatus()
      } else if (status == 2) {
        this.affirm = true
        this.formData.status = status
        this.formData.id = this.xqlist.id
      }
    },
    // 修改预约状态
    async modifyAppointmentStatus() {
      if (this.xqlist.status == 2) {
        const msg = await this.$confirm('您确定工作人员已上门吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (msg === 'confirm') {
          this.alterState()
        }
      } else {
        this.alterState()
      }
    },
    openAppointment() {
      this.alterProduct = true
      this.recompose.date1 = this.xqlist.doorTime.split(/\s+/)[0]
      this.recompose.time1 = this.xqlist.doorTime.split(/\s+/)[1]
      this.recompose.date2 = this.xqlist.doorTime2.split(/\s+/)[0]
      this.recompose.time2 = this.xqlist.doorTime2.split(/\s+/)[1]
    },
    async modifyAppointment() {
      if (this.recompose.date1 != undefined || this.recompose.time1 != '') {
        this.xqlist.doorTime = this.dayjs(this.recompose.date1).format('YYYY-MM-DD ') + '  ' + this.recompose.time1 + ':00'
        this.xqlist.doorTime2 = this.dayjs(this.recompose.date2).format('YYYY-MM-DD') + '  ' + this.recompose.time2 + ':00'
      }
      const { data: res } = await this.$http.post(`/admin/appointment/modifyAppointment`, this.xqlist)
      if (res.code == 200) {
        this.alterProduct = false
        this.getAppointmentInfo()
        this.$message.success('修改成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    async alterState() {
      const { data: res } = await this.$http.post('/admin/appointment/modifyAppointmentStatus', this.formData)
      if (res.code == 200 && res.data != 0) {
        this.$message.success('修改成功')
        this.affirm = false
        this.cancelTime = false
        this.formData.status = ''
        this.formData.remarks = ''
        this.formData.checkTime = ''
        this.getAppointmentInfo()
      } else {
        this.$message.error(res.msg)
        this.affirm = false
        this.cancelTime = false
      }
    },
    // 获取详情信息
    async getAppointmentInfo() {
      const { data: res } = await this.$http.get(`/admin/appointment/getAppointmentInfo?orderId=${this.appointmentid}`)
      this.xqlist = res.data
      if (this.xqlist.doorTime != null) {
        this.xqlist.doorTime = this.renderTime(res.data.doorTime)
      }
      if (this.xqlist.doorTime2 != null) {
        this.xqlist.doorTime2 = this.renderTime(res.data.doorTime2)
      }
      if (this.xqlist.doorTime3 != null) {
        this.xqlist.doorTime3 = this.renderTime(res.data.doorTime3)
      }
      if (this.xqlist.checkTime != null) {
        this.xqlist.checkTime = this.renderTime(res.data.checkTime)
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON()
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
    },
    // 删除预约
    async delAppointment() {
      const msg = await this.$confirm('您确认要删除该条预约吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/appointment/delAppointment?id=${this.xqlist.id}`)
        if (res.code == 200 && res.data != 0) {
          this.$message.success('删除成功')
          this.getAppointmentInfo()
          this.back()
        } else {
          this.$message.error('删除失败')
        }
      }
    },
    // 状态
    statusFormat(status) {
      if (status == 0) {
        return '已取消'
      } else if (status == 1) {
        return '已预约'
      } else if (status == 2) {
        return '已确认'
      } else if (status == 3) {
        return '已完成'
      }
    },
    // 返回产品列表
    back() {
      this.$router.push({ path: '/appointment', query: { pageNum: this.pageNum } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>