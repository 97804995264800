<!-- 代理模块 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="118px">
      <el-form-item label="用户名或手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>

      <el-form-item label="代理角色"
                    label-width="68px">
        <el-select size="medium"
                   v-model="queryParams.role"
                   clearable
                   placeholder="请选择角色">
          <el-option label="特级代理"
                     value="0"></el-option>
          <el-option label="市级代理"
                     value="1"></el-option>
          <el-option label="县级代理"
                     value="2"></el-option>
          <el-option label="个人代理"
                     value="3"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="上级用户名/手机号"
                    label-width="148px">
        <el-input size="medium"
                  v-model="queryParams.topKeyword"
                  clearable
                  placeholder="请输入上级用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
        <el-button v-if="authAttr>1"
                   type="success"
                   size="medium"
                   icon="el-icon-plus"
                   @click="addDialogVisible=true">添加代理</el-button>
      </el-form-item>
    </el-form>

    <template>
      <el-dialog title="添加代理人"
                 :visible.sync="addDialogVisible"
                 width="40%">
        <div class="demo-input-suffix">
          <el-form :model="addProxyList"
                   ref="addProxyList"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="代理人"
                          prop="userId">
              <el-select v-model="addProxyList.userId"
                         clearable
                         filterable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 2)"
                         placeholder="请输入用户手机号或姓名">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
            <el-form-item label="上级代理人">
              <el-select v-model="addProxyList.topUserId"
                         filterable
                         clearable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 1)"
                         placeholder="请输入上级手机号">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="代理角色"
                          prop="role">
              <el-select v-model="addProxyList.role"
                         placeholder="请选择">
                <el-option label="特级代理"
                           value="0"></el-option>
                <el-option label="市级代理"
                           value="1"></el-option>
                <el-option label="区县代理"
                           value="2"></el-option>
                <el-option label="个人代理"
                           value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="居间人">
              <el-select v-model="addProxyList.intermediary"
                         @click.native="userJumpChange"
                         filterable
                         clearable
                         :filter-method="(value) => getAddableUser(value, 3)"
                         placeholder="请输入居间人手机号">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="特级居间人">
              <el-select v-model="addProxyList.superIntermediary"
                         @click.native="userJumpChange"
                         filterable
                         clearable
                         :filter-method="(value) => getAddableUser(value,3 )"
                         placeholder="请输入特级居间人手机号">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="addProxy('addProxyList')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="proxyList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="phone"
                           align="center"
                           label="用户">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.phone,scope.row.id)">{{textFilter(scope.row.phone,scope.row.name)}}</el-link>
            </template>
          </el-table-column>

          <el-table-column prop="role"
                           align="center"
                           label="身份">
            <template slot-scope="scope">
              <el-tag type="danger"
                      size="medium"
                      v-if="scope.row.role==0">特级代理</el-tag>
              <el-tag type="success"
                      size="medium"
                      v-if="scope.row.role==1">市级代理</el-tag>
              <el-tag type="warning"
                      size="medium"
                      v-if="scope.row.role==2">县级代理</el-tag>
              <el-tag size="medium"
                      v-if="scope.row.role==3">个人代理</el-tag>
              <span style="padding:0px 1px">
                <el-tag type="info"
                        size="medium"
                        v-if="scope.row.staffCount>0">团队{{scope.row.staffCount}}人</el-tag>
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="topPhone"
                           align="center"
                           label="上级">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.topPhone)">{{textFilter(scope.row.topPhone,scope.row.topName)}}</el-link>

            </template>
          </el-table-column>

          <el-table-column prop="intermediaryPhone"
                           align="center"
                           label="居间人">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.intermediaryPhone)">{{textFilter(scope.row.intermediaryPhone,scope.row.intermediaryName)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="superIntermediaryPhone"
                           align="center"
                           label="特级居间人">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.superIntermediaryPhone)">{{textFilter(scope.row.superIntermediaryPhone,scope.row.superIntermediaryName)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           show-overflow-tooltip
                           label="创建时间"></el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template v-slot="{row}">
              <el-button-group>
                <el-button v-if="authAttr>1&&row.role!=3"
                           type="text"
                           size="mini"
                           icon="el-icon-setting"
                           @click="gototTeam(row.phone)">团队管理</el-button>
                <el-button v-if="authAttr>1&&row.type!=1"
                           type="text"
                           size="mini"
                           icon="el-icon-edit"
                           @click="modificationAgency(row)">修改</el-button>
                <el-button v-if="authAttr>1"
                           type="text"
                           size="mini"
                           icon="el-icon-delete"
                           @click="delProxy(row.id,row.staffCount)">删除</el-button>
              </el-button-group>

            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 修改身份 -->
      <template>
        <el-dialog title="修改代理身份"
                   :visible.sync="discountDialogs"
                   width="50%">
          <div class="demo-input-suffix">
            <el-form :model="changeList"
                     ref="changeIdentityList"
                     label-width="110px">

              <el-form-item label="居间人">
                <el-select v-model="changeList.intermediary"
                           @change="changeintermediary($event)"
                           clearable
                           filterable
                           @click.native="userJumpChange"
                           :filter-method="(value) => getAddableUser(value, 3)"
                           placeholder="请输入用户手机号">
                  <el-option v-for="item in options"
                             :key="item.id"
                             :label='item.label'
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="上级代理人">
                <el-select v-model="changeList.topUserId"
                           @change="changetopUserId($event)"
                           clearable
                           filterable
                           @click.native="userJumpChange"
                           :filter-method="(value) => getAddableUser(value, 1)"
                           placeholder="请输入上级手机号">
                  <el-option v-for="item in options"
                             :key="item.id"
                             :label='item.label'
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="特级居间人">
                <el-select v-model="changeList.superIntermediary"
                           @change="changeSuperIntermediary($event)"
                           clearable
                           filterable
                           @click.native="userJumpChange"
                           :filter-method="(value) => getAddableUser(value, 3)"
                           placeholder="请输入特级居间人手机号">
                  <el-option v-for="item in options"
                             :key="item.id"
                             :label='item.label'
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="discountDialogs = false">取 消</el-button>
            <el-button type="success"
                       @click="submitAgency('changeIdentityList')">提交</el-button>
          </span>
        </el-dialog>
      </template>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getProxyList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      addDialogVisible: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        role: '',
        topKeyword: ''
      },
      addProxyList: {
        topUserId: '',
        userId: '',
        role: '',
        intermediary: '',
        superIntermediary: ''
      },
      proxyList: [],
      total: 0,
      authAttr: '',
      rules: {
        userId: [{ required: true, message: '请选择用户', trigger: 'change' }],
        role: [{ required: true, message: '请选择代理身份', trigger: 'change' }]
      },
      options: [],
      discountDialogs: false,
      changeIdentityList: {
        id: '',
        intermediary: '',
        topUserId: '',
        superIntermediary: ''
      },
      changeList: {
        intermediary: '',
        topUserId: '',
        superIntermediary: ''
      },
      deletePrompt: ''
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getProxyList()
    this.getAuthAttr()
  },

  methods: {
    // 修改居间人发生变化
    changeintermediary(e) {
      this.changeIdentityList.intermediary = e
    },
    // 修改上级代理发生变化
    changetopUserId(e) {
      this.changeIdentityList.topUserId = e
    },
    // 修改特级居间人发生变化
    changeSuperIntermediary(e) {
      this.changeIdentityList.superIntermediary = e
    },
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getProxyList()
    },

    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-14')
      this.authAttr = authAttr
    },
    // 查询代理列表
    async getProxyList() {
      const { data: res } = await this.$http.get('/admin/proxy/getProxyList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.proxyList = res.data.list
      this.total = res.data.total
    },
    userJumpChange() {
      this.options = []
    },
    // 获取代理可添加人员
    async getAddableUser(keyword, type) {
      const { data: res } = await this.$http.get('/admin/proxy/getAddableUser', { params: { keyword: keyword, type: type } })
      if (res.code == 200) {
        this.options = []
        res.data.forEach((element) => {
          var obj = {}
          obj['label'] = `${element.phone}${element.name == null ? '' : '(' + element.name + ')'}`
          obj['id'] = element.id
          this.options.push(obj)
        })
      }
    },
    addProxy(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addDialogVisible = false
          const { data: res } = await this.$http.post('/admin/proxy/addProxy', this.addProxyList)
          if (res.code == 200 && res.data != 0) {
            this.getProxyList()
            this.$message.success('添加代理成功')
          } else {
            this.$message.warning(res.msg)
          }
          this.addProxyList = {}
        }
      })
    },
    // 删除代理
    delProxy(id, staffCount) {
      if (staffCount) {
        this.deletePrompt = '此操作将删除当前用户的代理身份并且移除团队的所有直接团员，是否继续?'
      } else {
        this.deletePrompt = '此操作将删除当前用户的代理身份, 是否继续?'
      }
      this.$confirm(this.deletePrompt, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(`/admin/proxy/delProxy?id=${id}`)
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getProxyList()
          } else {
            this.$message({
              type: 'info',
              message: res.msg
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    splicing(phone, name) {
      return (phone == null ? '' : phone) + (name == null ? '' : '(' + name + ')')
    },
    // 修改按钮
    modificationAgency(row) {
      this.changeList.intermediary = this.splicing(row.intermediaryPhone, row.intermediaryName)
      this.changeList.topUserId = this.splicing(row.topPhone, row.topName)
      this.changeList.superIntermediary = this.splicing(row.superIntermediaryPhone, row.superIntermediaryName)
      this.changeIdentityList.id = row.id
      this.changeIdentityList.intermediary = row.intermediary
      this.changeIdentityList.topUserId = row.topUserId
      this.changeIdentityList.superIntermediary = row.superIntermediary
      this.discountDialogs = true
    },
    submitAgency(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.discountDialogs = false
          const { data: res } = await this.$http.post('/admin/proxy/updateProxy', this.changeIdentityList)
          if (res.code == 200) {
            this.getProxyList()
            this.$message.success('修改用户身份成功')
          } else {
            this.$message.warning(res.msg)
          }
        }
      })
    },
    // 跳转代理详情
    async goAgencyInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, pageNum: this.queryParams.pageNum, type: 5 } })
    },
    // 跳转团队管理
    gototTeam(phone) {
      this.$router.push({ path: 'team', query: { phone: phone } })
    }
  }
}
</script>
<style lang='scss' scoped>
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>