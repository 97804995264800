<!-- 预约详情 -->
<template>
  <div>
    <el-card v-if="isAppointmentState">
      <el-descriptions>
        <el-descriptions-item label="预约号">{{appointmentList.id}}</el-descriptions-item>
        <el-descriptions-item label="用户手机号">
          <el-link type="primary"
                   @click="gotoUserInfo(appointmentList.userPhone)">{{appointmentList.userPhone}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="预约状态">
          <el-tag size="small">{{appointmentList.status==0?"已取消":appointmentList.status==1?'已预约':appointmentList.status==2?'已确认':'已完成'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="上门时间1"> {{appointmentList.doorTime}}</el-descriptions-item>
        <el-descriptions-item label="上门时间2"> {{appointmentList.doorTime2}}</el-descriptions-item>
        <el-descriptions-item label="联系人">{{appointmentList.name}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{appointmentList.phone}}</el-descriptions-item>
        <el-descriptions-item label="联系地址">{{appointmentList.address}}</el-descriptions-item>
        <el-descriptions-item v-if="appointmentList.checkTime"
                              label="确认时间">{{appointmentList.checkTime}}</el-descriptions-item>
        <el-descriptions-item v-if="appointmentList.sellerName"
                              label="代预约销售姓名">{{appointmentList.sellerName}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{appointmentList.createTime}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{appointmentList.remarks}}</el-descriptions-item>
        <el-descriptions-item label="取消原因">{{appointmentList.cancelReason}}</el-descriptions-item>
      </el-descriptions>
      <el-button v-if="appointmentList.status==1"
                 type="primary"
                 size="small"
                 @click="modificationTime(2)">确认预约时间</el-button>
      <el-button v-if="appointmentList.status==2"
                 type="primary"
                 size="small"
                 @click="modificationTime(3)">确认已上门</el-button>
      <el-button v-if="appointmentList.status==1||appointmentList.status==2"
                 type="primary"
                 size="small"
                 @click="openAppointment()">修改预约</el-button>
      <el-button v-if="appointmentList.status==1||appointmentList.status==2"
                 type="warning"
                 size="small"
                 @click="modificationTime(0)">取消预约</el-button>
      <el-button v-if="authAttr>2"
                 type="danger"
                 size="small"
                 icon="el-icon-delete"
                 @click="delAppointment()">删除预约</el-button>
      <div v-if="historyAppointmentInfoList.length>0">
        <div class="history">历史预约</div>
        <el-table :data="historyAppointmentInfoList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="id"
                           align="center"
                           label="预约号"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           :formatter="zt"
                           label="状态"></el-table-column>
          <el-table-column prop="doorTime"
                           align="center"
                           :formatter="dateFormat"
                           label="上门时间1"></el-table-column>
          <el-table-column prop="doorTime"
                           align="center"
                           :formatter="dateFormat"
                           label="上门时间2"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="联系电话"></el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="联系人"></el-table-column>
          <el-table-column prop="address"
                           align="center"
                           label="联系地址"></el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="创建时间"></el-table-column>

        </el-table>
      </div>

    </el-card>
    <div v-else>此订单无预约</div>

    <template>
      <el-dialog title="确认预约时间"
                 :visible.sync="affirm"
                 width="50%">
        <div class="demo-input-suffix">
          <el-radio-group v-model="radioTreaty"
                          @change="agreeChange">
            <el-radio v-model="radio"
                      :label=appointmentList.doorTime
                      border>{{appointmentList.doorTime}}</el-radio>
            <el-radio v-model="radio"
                      :label=appointmentList.doorTime2
                      border>{{appointmentList.doorTime2}}</el-radio>
            <el-radio v-if="appointmentList.doorTime3"
                      v-model="radio"
                      :label=appointmentList.doorTime3
                      border>{{appointmentList.doorTime3}}</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="affirm = false">取 消</el-button>
          <el-button type="success"
                     @click="modifyAppointmentStatus()"
                     submit>提交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="取消预约时间"
                 :visible.sync="cancelTime"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="form"
                   :model="formData"
                   label-width="80px">
            <el-form-item label="取消原因">
              <el-input v-model="formData.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="cancelTime = false">取 消</el-button>
          <el-button type="success"
                     @click="alterState()"
                     submit>提交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="修改预约"
                 :visible.sync="alterProduct"
                 width="600px">
        <el-form ref="form"
                 :model="appointmentList"
                 label-width="100px">
          <el-form-item label="联系人">
            <el-input v-model="appointmentList.name"
                      placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="appointmentList.phone"
                      placeholder="请输入联系电话" />
          </el-form-item>
          <el-form-item label="联系地址">
            <el-input v-model="appointmentList.address"
                      placeholder="请输入联系地址" />
          </el-form-item>
          <el-form-item label="上门时间"
                        required>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date"
                                placeholder="选择日期"
                                v-model="recompose.date1"
                                :picker-options="pickerOptions"
                                style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line"
                    :span="2">——</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-time-select placeholder="选择时间"
                                v-model="recompose.time1"
                                :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="上门时间2"
                        required>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker type="date"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                v-model="recompose.date2"
                                style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line"
                    :span="2">——</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-time-select placeholder="选择时间"
                                v-model="recompose.time2"
                                :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="appointmentList.remarks"
                      placeholder="请输入备注信息" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProduct = false">取 消</el-button>
          <el-button type="primary"
                     @click="modifyAppointment">提 交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Booking',
  data() {
    return {
      isAppointmentState: true,
      authAttr: '',
      radioTreaty: '1',
      radio: '1',
      pageNum: '',
      appointmentList: {},
      orderId: '',
      affirm: false,
      cancelTime: false,
      alterProduct: false,
      formData: {
        id: '',
        status: '',
        remarks: '',
        checkTime: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      recompose: {
        time2: '',
        time1: '',
        date: '',
        date2: ''
      },
      historyAppointmentInfoList: []
    }
  },
  mounted() {
    this.pageNum = this.$route.query.pageNum
    this.orderId = this.$route.query.orderId
    this.getAuthAttr()
    this.getAppointmentInfo()
  },

  methods: {
    dateFormat(row, column) {
      var dT = new Date(row.doorTime) //row 表示一行数据, dateTime 表示要格式化的字段名称
      return dT.getFullYear() + '-' + (dT.getMonth() + 1) + '-' + dT.getDate() + ' ' + dT.getHours() + ':' + dT.getMinutes() + ':' + dT.getSeconds()
    },
    zt(row) {
      if (row.status == 0) {
        return '已取消'
      } else if (row.status == 1) {
        return '已预约'
      } else if (row.status == 2) {
        return '已确认'
      } else if (row.status == 3) {
        return '已完成'
      }
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-7')
      this.authAttr = authAttr
    },
    agreeChange(val) {
      this.formData.checkTime = val
    },
    modificationTime(status) {
      if (status == 0) {
        this.cancelTime = true
        this.formData.status = status
        this.formData.id = this.appointmentList.id
      } else if (status == 3) {
        this.formData.status = status
        this.formData.id = this.appointmentList.id
        this.modifyAppointmentStatus()
      } else if (status == 2) {
        this.affirm = true
        this.formData.status = status
        this.formData.id = this.appointmentList.id
      }
    },
    // 修改预约状态
    async modifyAppointmentStatus() {
      if (this.appointmentList.status == 2) {
        const msg = await this.$confirm('您确定工作人员已上门吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (msg === 'confirm') {
          this.alterState()
        }
      } else {
        this.alterState()
      }
    },
    openAppointment() {
      this.alterProduct = true
      this.recompose.date1 = this.appointmentList.doorTime.split(/\s+/)[0]
      this.recompose.time1 = this.appointmentList.doorTime.split(/\s+/)[1]
      this.recompose.date2 = this.appointmentList.doorTime2.split(/\s+/)[0]
      this.recompose.time2 = this.appointmentList.doorTime2.split(/\s+/)[1]
    },
    async modifyAppointment() {
      if (this.recompose.date1 != undefined || this.recompose.time1 != '') {
        this.appointmentList.doorTime = this.dayjs(this.recompose.date1).format('YYYY-MM-DD ') + '  ' + this.recompose.time1 + ':00'
        this.appointmentList.doorTime2 = this.dayjs(this.recompose.date2).format('YYYY-MM-DD') + '  ' + this.recompose.time2 + ':00'
      }
      const { data: res } = await this.$http.post(`/admin/appointment/modifyAppointment`, this.appointmentList)
      if (res.code == 200) {
        this.alterProduct = false
        this.getAppointmentInfo()
        this.$message.success('修改成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    async alterState() {
      const { data: res } = await this.$http.post('/admin/appointment/modifyAppointmentStatus', this.formData)
      if (res.code == 200 && res.data != 0) {
        this.$message.success('修改成功')
        this.affirm = false
        this.cancelTime = false
        this.formData.status = ''
        this.formData.remarks = ''
        this.formData.checkTime = ''
        this.getAppointmentInfo()
      } else {
        this.$message.error(res.msg)
        this.affirm = false
        this.cancelTime = false
      }
    },
    // 获取详情信息
    async getAppointmentInfo() {
      const { data: res } = await this.$http.get(`/admin/appointment/getAppointmentInfo?orderId=${this.orderId}`)
      if (res.data == null) {
        this.isAppointmentState = false
      } else {
        this.appointmentList = res.data
        this.historyAppointmentInfoList = res.data.historyAppointmentInfoList

        if (this.appointmentList.doorTime != null) {
          this.appointmentList.doorTime = this.renderTime(res.data.doorTime)
        }
        if (this.appointmentList.doorTime2 != null) {
          this.appointmentList.doorTime2 = this.renderTime(res.data.doorTime2)
        }
        if (this.appointmentList.doorTime3 != null) {
          this.appointmentList.doorTime3 = this.renderTime(res.data.doorTime3)
        }
        if (this.appointmentList.checkTime != null) {
          this.appointmentList.checkTime = this.renderTime(res.data.checkTime)
        }
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON()
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
    },
    // 删除预约
    async delAppointment() {
      const msg = await this.$confirm('您确认要删除该条预约吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/appointment/delAppointment?id=${this.appointmentList.id}`)
        if (res.code == 200 && res.data != 0) {
          this.$message.success('删除成功')
          this.getAppointmentInfo()
          this.$emit('watchChild')
        } else {
          this.$message.error('删除失败')
        }
      }
    },
    // 状态
    statusFormat(status) {
      if (status == 0) {
        return '已取消'
      } else if (status == 1) {
        return '已预约'
      } else if (status == 2) {
        return '已确认'
      } else if (status == 3) {
        return '已完成'
      }
    },
    // 返回预约列表
    back() {
      this.$router.push({ path: '/appointment', query: { pageNum: this.pageNum } })
    },
    gotoUserInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, type: 1 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.history {
  font-size: 17px;
  font-weight: 500;
  margin: 20px 0px;
}
</style>