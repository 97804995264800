<!-- 用户下单 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryForm"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号"
                    prop="nickName">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入用户名或手机号"
                  clearable
                  size="small" />
        <!-- @keyup.enter.native="handleQuery"  -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="mini"
                   @click="search()">搜索</el-button>
        <!-- @click="handleQuery" -->

        <!-- @click="resetQuery" -->
      </el-form-item>
    </el-form>

    <el-table :data="tableData"
              align="center"
              style="width: 100%"
              :header-cell-style="{background: '#f8f8f9'}">

      <el-table-column label="姓名"
                       align="center"
                       prop="userName" />
      <el-table-column label="手机"
                       align="center"
                       prop="phone" />
      <!--<el-table-column label="头像url" align="center" prop="txUrl" />-->
      <el-table-column label="订单号"
                       align="center"
                       prop="id" />
      <el-table-column label="价格"
                       align="center"
                       prop="price" />
      <el-table-column label="产品名称"
                       align="center"
                       prop="productName" />
      <el-table-column label="支付时间"
                       align="center"
                       prop="payTime" />
      <el-table-column label="状态"
                       align="center"
                       prop="status"
                       :formatter="state" />
      <el-table-column label="操作"
                       align="center"
                       class-name="small-padding fixed-width">
        <template v-slot="{row}">
          <el-button-group>
            <el-button v-if="row.status==0||row.status==10"
                       type="default"
                       size="mini"
                       icon="el-icon-edit"
                       @click="delHandler(row.id)">预约</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryParams.pageNum"
                       :pager-count="2"
                       :page-sizes="[10, 20, 30, 50]"
                       :page-size="queryParams.pageSize"
                       layout="total, prev, pager, next"
                       :total="total">
        </el-pagination>
      </el-row>
    </template>

    <!-- 添加客户预约 -->
    <template>
      <el-dialog title="客户预约"
                 :visible.sync="addDialogVisible"
                 width="600px">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="90px">
            <el-form-item label="联系人"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入联系人（必填）"></el-input>
            </el-form-item>
            <el-form-item label="联系电话"
                          prop="phone">
              <el-input v-model="recompose.phone"
                        placeholder="请输入联系电话（必填）"></el-input>
            </el-form-item>
            <el-form-item label="联系地址"
                          prop="address">
              <el-input v-model="recompose.address"
                        placeholder="请输入联系地址"></el-input>
            </el-form-item>
            <el-form-item label="上门时间"
                          required>
              <el-col :span="11">
                <el-form-item prop="date1">
                  <el-date-picker type="date"
                                  placeholder="选择日期"
                                  v-model="recompose.date1"
                                  :picker-options="pickerOptions"
                                  style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line"
                      :span="2">——</el-col>
              <el-col :span="11">
                <el-form-item prop="time1">
                  <el-time-select placeholder="选择时间"
                                  v-model="recompose.time1"
                                  :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                  </el-time-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="上门时间2"
                          required>
              <el-col :span="11">
                <el-form-item prop="date2">
                  <el-date-picker type="date"
                                  placeholder="选择日期"
                                  :picker-options="pickerOptions"
                                  v-model="recompose.date2"
                                  style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line"
                      :span="2">——</el-col>
              <el-col :span="11">
                <el-form-item prop="time2">
                  <el-time-select placeholder="选择时间"
                                  v-model="recompose.time2"
                                  :picker-options="{start: '08:00',step: '01:00',end: '18:00',}">
                  </el-time-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="备注"
                          prop="remarks">
              <el-input v-model="recompose.remarks"
                        placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      total: 0,
      tableData: [],
      //添加客户预约
      recompose: {
        phone: '',
        name: '',
        address: '',
        date1: '',
        date2: '',
        time1: '',
        time2: '',
        remarks: '',
        orderId: ''
      },
      //客户预约传参
      client: {
        phone: '',
        name: '',
        address: '',
        doorTime: '',
        doorTime2: '',
        remarks: '',
        orderId: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        date1: [{ type: 'date', required: true, message: '请选择上门日期', trigger: 'change' }],
        date2: [{ type: 'date', required: true, message: '请选择上门2日期', trigger: 'change' }],
        time2: [{ required: true, message: '请选择上门2时间', trigger: 'change' }],
        time1: [{ required: true, message: '请选择上门时间', trigger: 'change' }]
      },
      addDialogVisible: false
    }
  },
  mounted() {
    this.getOrderListBySeller()
  },
  methods: {
    // 搜索
    search() {
      this.queryParams.pageNum = 1
      this.getOrderListBySeller()
    },
    delHandler(id) {
      this.recompose.orderId = id
      this.addDialogVisible = true
    },
    // 查询表格数据
    async getOrderListBySeller() {
      const { data: res } = await this.$http.get('/admin/seller/getOrderListBySeller', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getOrderListBySeller()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getOrderListBySeller()
    },
    // 提交
    submitHandler(formName) {
      this.client.doorTime = this.dayjs(this.recompose.date1).format('YYYY-MM-DD ') + '  ' + this.recompose.time1 + ':00'
      this.client.doorTime2 = this.dayjs(this.recompose.date2).format('YYYY-MM-DD') + '  ' + this.recompose.time2 + ':00'
      this.client.name = this.recompose.name
      this.client.remarks = this.recompose.remarks
      this.client.phone = this.recompose.phone
      this.client.address = this.recompose.address
      this.client.orderId = this.recompose.orderId
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/seller/addAppointmentBySeller', this.client)
          if (res.code == 200) {
            this.addDialogVisible = false
            this.getOrderListBySeller()
            this.$message.success('预约上门成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    state(row) {
      if (row.status == 0) {
        return '未采样'
      } else if (row.status == 11) {
        return '已预约（未上门）'
      } else if (row.status == 12) {
        return '已预约（确定上门）'
      } else if (row.status == 13) {
        return '已预约（已上门）'
      } else if (row.status == 10) {
        return '已预约（已取消）'
      } else if (row.status == 2) {
        return '已采样'
      } else if (row.status == 3) {
        return '已出报告'
      } else {
        return '已邮寄报告'
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.line {
  text-align: center;
}
</style>