<!-- 推荐记录管理 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryForm"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号"
                    prop="nickName">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入用户名或手机号"
                  clearable
                  size="small" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="mini"
                   @click="search()">搜索</el-button>
        <el-button type="success"
                   icon="el-icon-search"
                   size="mini"
                   @click="RecommendTree()">所有推荐</el-button>
      </el-form-item>
    </el-form>
    <el-card>
      <el-table :data="tableData"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column label="用户名"
                         align="center"
                         prop="userName" />
        <el-table-column label="手机号"
                         align="center"
                         prop="phone" />
        <el-table-column label="积分"
                         align="center"
                         prop="score" />
        <el-table-column label="轻子"
                         align="center"
                         prop="lepton" />
        <el-table-column label="夸克"
                         align="center"
                         prop="quark" />

        <el-table-column label="层级名"
                         align="center"
                         prop="levelEng" />
        <el-table-column label="推荐保护期"
                         align="center"
                         prop="inProtectTime">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.inProtectTime"
                    type="success">推荐保护期</el-tag>
            <el-tag v-else
                    type="warning">非推荐保护期</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="text"
                       @click="viewSubordinates(scope.row.phone)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getRecommendRecordList" />
        </el-row>
      </template>
    </el-card>
    <template>
      <el-dialog title="推荐列表"
                 :visible.sync="dialogTableVisible">
        <el-tree :data="data"
                 :props="defaultProps">
          <span class="custom-tree-node"
                slot-scope="{data}">
            <span>
              {{data.phone}}{{data.userName==null?'':'——'+data.userName}}
            </span>
          </span>

        </el-tree>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      //推荐传参
      recommendList: {
        userId: ''
      },
      total: 0,
      tableData: [],
      dialogTableVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'userName'
      }
    }
  },
  mounted() {
    this.getRecommendRecordList()
  },
  methods: {
    // 搜索
    search() {
      this.queryParams.pageNum = 1
      this.getRecommendRecordList()
    },
    // 查询表格数据
    async getRecommendRecordList() {
      const { data: res } = await this.$http.get('/admin/assets/getRecommendRecordList', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 详情跳转
    viewSubordinates(phone) {
      this.$router.push({ path: '/userInfo', query: { phone: phone, type: 3 } })
    },

    //查询所有推荐树
    RecommendTree() {
      this.recommendList.userId = ''
      this.dialogTableVisible = true
      this.getRecommendTree()
    },

    // 查询推荐树
    async getRecommendTree() {
      const { data: res } = await this.$http.get('/admin/assets/getRecommendTree', { params: this.recommendList })
      if (res.code != 200) return this.$message.error(res.msg)
      this.data = res.data
      this.data.forEach((item, index) => {
        if (item.children != null) {
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>