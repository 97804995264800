<!--人员管理  -->
<template>
  <div>
    <!-- 顶部搜索 -->
    <el-form label-width="80px"
             v-if="authAttr>1"
             ref="myform"
             size="small"
             :inline="true">
      <el-form-item v-if="authAttr>2"
                    label="查询管理员"
                    label-width="100px"
                    prop="zhzt">
        <el-select v-model="queryParams.type"
                   placeholder="请选择">
          <el-option label="查所有人员"
                     value="0"></el-option>
          <el-option v-if="authAttr>2"
                     label="查产品管理员"
                     value="2"></el-option>
          <el-option v-if="authAttr>2"
                     label="查销售管理员"
                     value="3"></el-option>
          <el-option v-if="authAttr>2"
                     label="查所有销售"
                     value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button v-if="authAttr>2"
                   type="primary"
                   icon="el-icon-search"
                   size="medium"
                   @click="search">搜索</el-button>
        <el-button v-if="authAttr>1"
                   @click="addAdmin"
                   type="success"
                   plain
                   size="medium"
                   icon="el-icon-plus">新增管理员</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <template>
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">

        </el-row>
      </template>
      <!-- 表格区域 -->
      <el-table :data="AdminList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="username"
                         align="center"
                         label="用户名"
                         width="150">
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         label="状态"
                         width="200">
          <template slot-scope="scope">
            <span v-if="authAttr<=1">
              <span v-if="scope.row.status==0">已启用</span>
              <span v-if="scope.row.status==1">已禁用</span>
            </span>
            <el-switch v-if="authAttr>1"
                       v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="0"
                       :inactive-value="1"
                       active-text="启用"
                       inactive-text="禁用"
                       @change="updateBrandStatus(scope.row)"></el-switch>
          </template>

        </el-table-column>

        <el-table-column prop="avatar"
                         align="center"
                         label="头像"
                         width="200">
          <template slot-scope="scope">
            <el-image v-if="scope.row.avatar"
                      style="width: 100px; height: 100px"
                      :preview-src-list="srcList"
                      :src="$url+scope.row.avatar">
              <div slot="error"
                   class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>

          </template>
        </el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="手机号"
                         width="150">
        </el-table-column>
        <el-table-column prop="email"
                         align="center"
                         label="邮箱">
        </el-table-column>
        <el-table-column prop="identity"
                         align="center"
                         :formatter="identity"
                         label="身份">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="authAttr>2"
                       type="danger"
                       size="mini"
                       @click="delAdmin(scope.$index,scope.row.id)">删除</el-button>
            <el-button v-if="authAttr>2&&scope.row.identity!=4"
                       type="success"
                       size="mini"
                       @click="editPermission(scope.row.id)">编辑权限</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getAdminList" />
        </el-row>
      </template>
    </el-card>
    <!-- 新增管理员 -->
    <template>
      <el-dialog title="添加管理员"
                 :visible.sync="addDialogBox"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="addAdminObj"
                   :rules="rules"
                   ref="addAdminObj"
                   label-width="80px">
            <el-form-item label="用户名"
                          prop="username">
              <el-input v-model="addAdminObj.username"
                        placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码"
                          prop="password">
              <el-input v-model="addAdminObj.password"
                        show-password
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="身份"
                          prop="identity">
              <el-select v-model="addAdminObj.identity"
                         placeholder="请选择身份">
                <el-option v-for="item in identityList"
                           :key="item.id"
                           :label="item.role"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="addAdminObj.phone"
                        placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="addAdminObj.email"
                        placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
              <el-checkbox v-model="continuePasswordFlag">不清除密码和身份</el-checkbox>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogBox = false">取 消</el-button>
          <el-button type="primary"
                     @click="registerAdmin('addAdminObj')">添加</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },

  data() {
    let checkEmail = (rule, value, callback) => {
      //验证邮箱
      const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (this.addAdminObj.email != '' && !regEmail.test(this.addAdminObj.email)) {
        callback(new Error('请输入有效的邮箱'))
      }
      callback()
    }
    return {
      AdminList: [],
      //查询管理员传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0'
      },
      total: 0,

      // 控制修改用户名
      alteruser: false,
      // 修改用户信息传参
      recompose: {
        type: '',
        oldPassword: '',
        newPassword: '',
        newUsername: '',
        avatar: ''
      },
      // 新增信息
      addDialogBox: false,
      // 新增传参
      addAdminObj: {
        username: '',
        password: '',
        identity: '',
        phone: '',
        email: ''
      },
      identityList: [],
      authAttr: '',
      continueAddFlag: false,
      continuePasswordFlag: false,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        identity: [{ required: true, message: '请选择身份', trigger: 'change' }],
        phone: [{ min: 11, max: 11, message: '手机号格式错误', trigger: 'blur' }]
      },
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg', 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg']
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getAdminList()
    this.getIdentityList()
    this.getAuthAttr()
  },
  methods: {
    // 搜索按钮
    search() {
      this.queryParams.pageNum = 1
      this.getAdminList()
    },
    // 编辑权限
    async editPermission(id) {
      this.$router.push({ path: 'adminAuth', query: { id: id, pageNum: this.queryParams.pageNum } })
    },
    // 删除
    async delAdmin(index, id) {
      const msg = await this.$confirm('你确定要删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/admin/delAdmin?id=${id}`)
        if (res.code != 200) return this.$message.error(res.msg)
        this.AdminList.splice(index, 1)
        this.$message.success('注销成功')
      }
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-5')
      this.authAttr = authAttr
    },
    // 新增管理员
    registerAdmin(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            `/admin/admin/registerAdmin?username=${this.addAdminObj.username}&password=${this.addAdminObj.password}&identity=${this.addAdminObj.identity}&phone=${this.addAdminObj.phone}&email=${this.addAdminObj.email}`
          )
          if (this.continueAddFlag) {
            if (this.continuePasswordFlag) {
              this.addAdminObj.username = ''
            } else {
              this.addAdminObj = {}
            }
          } else {
            this.addDialogBox = false
          }
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) {
            if (this.continuePasswordFlag) {
              this.addAdminObj.username = ''
              this.getAdminList()
              return this.$message.success('新增管理员成功')
            } else {
              this.addAdminObj = {}
              this.getAdminList()
              return this.$message.success('新增管理员成功')
            }
          }
        }
      })
    },

    async getAdminList() {
      const { data: res } = await this.$http.get('/admin/admin/getAdminList', { params: this.queryParams })
      if (res.code != 200) return this.$message.error(res.msg)
      this.AdminList = res.data.list
      this.total = res.data.total
      this.srcList = []
      res.data.list.forEach((e) => {
        if (e.avatar != null) {
          this.srcList.push(this.$url + e.avatar)
        }
      })
    },
    async getIdentityList() {
      const { data: res } = await this.$http.get('/admin/admin/getIdentityList')
      res.data.forEach((element) => {
        if (element.id == 2) {
          element.role = '产品管理员'
        } else if (element.id == 3) {
          element.role = '销售管理员'
        } else {
          element.role = '销售'
        }
      })
      this.identityList = res.data
    },
    // 身份
    identity(row) {
      if (row.identity == 1) {
        return '超级管理员'
      } else if (row.identity == 2) {
        return '产品管理员'
      } else if (row.identity == 3) {
        return '销售管理员'
      } else {
        return '销售'
      }
    },
    addAdmin() {
      this.addDialogBox = true
    },
    // 修改状态
    async updateBrandStatus(row) {
      const { data: res } = await this.$http.post(`/admin/admin/setAdminStatus?id=${row.id}&status=${row.status}`)
    }
  }
}
</script>
<style lang='scss' scoped>
</style>