<!-- 团队管理 -->
<template>
  <div>
    <div>
      <i class="el-icon-arrow-left"
         style="color:#5aabff; font-weight:800"></i>
      <el-button @click="back"
                 type="text"
                 size="small"
                 style="font-weight:800">返回</el-button>
    </div>
    <el-card class="box-card">
      <el-descriptions title="团队代理信息">
        <template slot="extra">
          <el-button v-if="authAttr>1"
                     type="success"
                     size="medium"
                     icon="el-icon-plus"
                     @click="addDialogVisible=true">添加团队成员</el-button>
        </template>
        <el-descriptions-item label="根代理账户">{{textFilter(teamList.phone,teamList.username)}}</el-descriptions-item>
        <el-descriptions-item label="代理角色">
          <el-tag size="small">{{teamList.proxyRole==1?'市级代理':teamList.proxyRole==2?'区县代理':teamList.proxyRole==0?'特级代理':'个人代理'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="团队居间人">
          {{textFilter(teamList.intermediaryPhone,teamList.intermediaryName)}}
        </el-descriptions-item>
        <el-descriptions-item label="团队特级居间人">
          {{textFilter(teamList.superIntermediaryPhone,teamList.superIntermediaryName)}}
        </el-descriptions-item>
        <el-descriptions-item label="所属团队"
                              v-if=teamList.belongingTeamPhone>
          <el-link :underline="false"
                   type="primary"
                   @click.native="gotoTeam(teamList.belongingTeamPhone)"> {{textFilter(teamList.belongingTeamPhone,teamList.belongingTeamName)}}</el-link>

        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <h1 style="margin: 20px 10px;">团队成员</h1>

    <template>
      <el-dialog title="添加团队成员"
                 :visible.sync="addDialogVisible"
                 width="40%">
        <div class="demo-input-suffix">
          <el-form :model="addStafProxyList"
                   ref="addStafProxyList"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="团队成员"
                          prop="userId">
              <el-select v-model="addStafProxyList.userId"
                         clearable
                         filterable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 2)"
                         placeholder="请输入用户手机号或姓名">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="addStaffProxy('addStafProxyList')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="proxyList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="phone"
                           align="center"
                           label="用户">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.phone,scope.row.id)">{{textFilter(scope.row.phone,scope.row.name)}}</el-link>
            </template>
          </el-table-column>

          <el-table-column prop="role"
                           align="center"
                           width="100"
                           label="身份">
            <template slot-scope="scope">

              <el-tag type="success"
                      size="medium"
                      v-if="scope.row.role==1">市级代理</el-tag>
              <el-tag type="warning"
                      size="medium"
                      v-if="scope.row.role==2">县级代理</el-tag>
              <el-tag size="medium"
                      v-if="scope.row.role==3">个人代理</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="topPhone"
                           align="center"
                           label="上级">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goAgencyInfo(scope.row.topPhone)">{{textFilter(scope.row.topPhone,scope.row.topName)}}</el-link>

            </template>
          </el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           show-overflow-tooltip
                           label="创建时间"></el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template v-slot="{row}">
              <el-button-group>

                <el-button v-if="authAttr>1"
                           type="danger"
                           size="mini"
                           icon="el-icon-delete"
                           @click="delProxy(row.id)">删除</el-button>
              </el-button-group>

            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getTeamProxyInfo" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      teamList: {},
      addDialogVisible: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: ''
      },
      addStafProxyList: {
        topUserId: '',
        userId: '',
        role: '',
        rootUserId: ''
      },

      proxyList: [],
      total: 0,
      authAttr: '',
      rules: {
        userId: [{ required: true, message: '请选择用户', trigger: 'change' }]
      },
      options: []
    }
  },
  created() {
    this.queryParams.phone = this.$route.query.phone
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  watch: {
    // 路由发生变化刷新整个组件
    $route() {
      window.location.reload()
    }
  },
  mounted() {
    this.getTeamProxyInfo()
    this.getAuthAttr()
  },

  methods: {
    gotoTeam(phone) {
      this.$router.push({ path: '/redirect', query: { router: `/team?phone=${phone}` } })
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-14')
      this.authAttr = authAttr
    },
    // 查询代理列表
    async getTeamProxyInfo() {
      const { data: res } = await this.$http.get('/admin/proxy/getTeamProxyInfo', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.proxyList = res.data.pageInfo.list
      this.teamList = res.data
      this.addStafProxyList.role = res.data.proxyRole
      this.total = res.data.pageInfo.total
      this.addStafProxyList.rootUserId = res.data.userId
    },
    userJumpChange() {
      this.options = []
    },
    // 获取代理可添加人员
    async getAddableUser(keyword, type) {
      const { data: res } = await this.$http.get('/admin/proxy/getAddableUser', { params: { keyword: keyword, type: type } })
      if (res.code == 200) {
        this.options = []
        res.data.forEach((element) => {
          var obj = {}
          obj['label'] = `${element.phone}${element.name == null ? '' : element.name == '' ? '' : '(' + element.name + ')'}`
          obj['id'] = element.id
          this.options.push(obj)
        })
      }
    },
    addStaffProxy(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addDialogVisible = false
          const { data: res } = await this.$http.post('/admin/proxy/addStaffProxy', this.addStafProxyList)
          if (res.code == 200 && res.data != 0) {
            this.$message.success('添加代理成功')
          } else {
            this.$message.warning(res.msg)
          }
          this.getTeamProxyInfo()
          this.addStafProxyList.role = ''
          this.addStafProxyList.topUserId = ''
          this.addStafProxyList.userId = ''
        }
      })
    },
    // 删除代理
    delProxy(id) {
      this.$confirm('此操作将删除当前用户的代理身份, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(`/admin/proxy/delProxy?id=${id}`)
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getTeamProxyInfo()
          } else {
            this.$message({
              type: 'info',
              message: res.msg
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 跳转代理详情
    async goAgencyInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, pageNum: this.queryParams.pageNum, type: 5 } })
    },
    // 返回用户列表
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='scss' scoped>
.box-card {
  margin-bottom: 10px;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>