<!-- 产品详情 -->
<template>
  <div>
    <el-form ref="form"
             :model="xqlist"
             label-width="100px">
      <el-form-item label="产品名"
                    prop="name">
        <el-col :span="8">
          <el-input v-model="xqlist.name"
                    :disabled="true" />
        </el-col>

      </el-form-item>
      <el-form-item label="产品名缩写"
                    prop="shortName">
        <el-col :span="8">
          <el-input v-model="xqlist.shortName"
                    :disabled="true"
                    placeholder="产品名缩写" />
        </el-col>
      </el-form-item>
      <el-form-item label="产品简介"
                    prop="shortIntroduce">
        <el-input v-model="xqlist.shortIntroduce"
                  :disabled="true" />
      </el-form-item>
      <el-form-item label="产品详情"
                    prop="introduce">
        <el-input v-html="xqlist.introduce"
                  :disabled="true" />
      </el-form-item>
      <el-form-item label="产品封面"
                    prop="cover">
        <el-image style="width: 100px; height: 100px"
                  :preview-src-list="carouselList"
                  :src="carouselList[0]">
          <div slot="error"
               class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>

      </el-form-item>

      <el-form-item label="产品轮播图"
                    prop="image">
        <el-image style="width: 100px; height: 100px"
                  :preview-src-list=" xqlist.images"
                  :src="xqlist.images[index]"
                  v-for="(item,index) in xqlist.images"
                  :key="index">
          <div slot="error"
               class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>

      </el-form-item>

      <el-form-item label="产品介绍图"
                    prop="infoImageList">
        <el-image style="width: 100px; height: 100px"
                  :preview-src-list=" xqlist.infoImageList"
                  :src="xqlist.infoImageList[index]"
                  v-for="(item,index) in xqlist.infoImageList"
                  :key="index">
          <div slot="error"
               class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>

      </el-form-item>

      <el-form-item label="价格"
                    prop="price">
        <el-col :span="8">
          <el-input v-model="xqlist.price"
                    :disabled="true" />
        </el-col>
      </el-form-item>

      <el-form-item label="销售量"
                    prop="price">
        <el-col :span="8">
          <el-input v-model="xqlist.salesVolume"
                    :disabled="true" />
        </el-col>
      </el-form-item>

      <el-form-item label="分类"
                    prop="type">
        <el-col :span="12">
          <el-input v-model="xqlist.type"
                    :disabled="true" />
        </el-col>

      </el-form-item>

      <el-form-item label="创建时间"
                    prop="createTime">
        <el-col :span="8">
          <el-input v-model="xqlist.createTime"
                    :disabled="true"
                    placeholder="创建时间" />
        </el-col>

      </el-form-item>
      <el-form-item label="修改时间"
                    prop="updateTime">
        <el-col :span="8">
          <el-input v-model="xqlist.updateTime"
                    :disabled="true"
                    placeholder="修改时间" />
        </el-col>
      </el-form-item>
      <el-button @click="back"
                 type="success">返回产品列表</el-button>
      <el-button v-if="authAttr>1"
                 type="primary"
                 icon="el-icon-edit"
                 @click="updateContent()">修改内容</el-button>
      <el-button v-if="authAttr>1"
                 type="primary"
                 icon="el-icon-edit"
                 @click="updateImages()">修改封面和轮播图</el-button>
      <el-button v-if="authAttr>1"
                 type="primary"
                 icon="el-icon-edit"
                 @click="OpenProductInfoImageFrame()">修改产品介绍图片</el-button>
      <el-button v-if="authAttr>1"
                 type="primary"
                 icon="el-icon-edit"
                 @click="OpenProductRebate()">修改产品返利列表</el-button>
      <el-button v-if="authAttr>2"
                 type="danger"
                 icon="el-icon-delete"
                 @click="delHandler()">失效</el-button>
    </el-form>
    <!-- 修改封面和轮播图对话框 -->
    <template>
      <el-dialog title="修改封面和轮播图"
                 :visible.sync="alterProductImages"
                 width="50%">
        <p>修改图片为重新上传（封面和图片至少选择一个）</p><br>
        <div class="demo-input-suffix">
          <el-form ref="form"
                   label-width="80px">
            <el-form-item label="图片(多选)">
              <el-upload action="#"
                         ref="upload3"
                         :headers="headers"
                         multiple
                         :data='recompose'
                         :auto-upload="false"
                         :file-list="fileList"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="封面(单选)">
              <el-upload action="#"
                         ref="upload4"
                         :headers="headers"
                         :limit="1"
                         :data='recompose'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-change="handleChange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProductImages = false">取 消</el-button>
          <el-button type="success"
                     @click="updateArticleImages">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改产品介绍图片 -->
    <template>
      <el-dialog title="修改产品介绍图片"
                 :visible.sync="ProductInfoImage"
                 width="50%">
        <p>修改图片为重新上传</p><br>
        <div class="demo-input-suffix">
          <el-form ref="form"
                   label-width="80px">
            <el-form-item label="图片(多选)">
              <el-upload action="#"
                         ref="productImg"
                         :headers="headers"
                         multiple
                         :auto-upload="false"
                         :file-list="fileList"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="ProductInfoImage = false">取 消</el-button>
          <el-button type="success"
                     @click="uploadProductInfoImage">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <template>
      <el-dialog title="修改信息"
                 :visible.sync="alterProduct"
                 width="50%">
        <el-form ref="form"
                 :model="xqlist"
                 label-width="100px">
          <el-form-item label="产品名"
                        prop="name">
            <el-input v-model="xqlist.name"
                      placeholder="请输入产品名" />
          </el-form-item>
          <el-form-item label="产品名缩写"
                        prop="shortName">
            <el-input v-model="xqlist.shortName"
                      placeholder="产品名缩写" />
          </el-form-item>
          <el-form-item label="产品简介"
                        prop="shortIntroduce">
            <el-input v-model="xqlist.shortIntroduce"
                      placeholder="请输入产品简介" />
          </el-form-item>
          <el-form-item label="产品详情"
                        prop="introduce">
            <el-input type="textarea"
                      v-model="xqlist.introduce"></el-input>

          </el-form-item>
          <el-form-item label="价格"
                        prop="price">
            <el-input v-model="xqlist.price"
                      placeholder="请输入价格" />
          </el-form-item>
          <el-form-item label="销量"
                        prop="price">
            <el-input v-model="xqlist.salesVolume"
                      placeholder="请输入销量" />
          </el-form-item>
          <el-form-item label="分类"
                        prop="type">
            <el-input v-model="xqlist.type"
                      placeholder="请输入分类" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProduct = false">取 消</el-button>
          <el-button type="primary"
                     @click="changexx">提 交</el-button>
        </span>
      </el-dialog>
    </template>

    <template>
      <el-dialog title="修改产品返利列表"
                 :visible.sync="productRebateDialog"
                 width="50%">
        <div class="chain">
          <div class="chain1">链1</div>
          <div class="chainRight">
            <div class="identity">市级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line1[0]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
          <div class="chainRight">
            <div class="identity">县级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line1[1]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
          <div class="chainRight">
            <div class="identity">个人:</div>
            <div><el-input type="number"
                        v-model="productRebateList.line1[2]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
        </div>
        <div class="chain">
          <div class="chain1">链2</div>
          <div class="chainRight">
            <div class="identity">市级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line2[0]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
          <div class="chainRight">
            <div class="identity">县级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line2[1]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
        </div>
        <div class="chain">
          <div class="chain1">链3</div>
          <div class="chainRight">
            <div class="identity">市级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line3[0]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
          <div class="chainRight">
            <div class="identity">个人:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line3[1]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
        </div>

        <div class="chain">
          <div class="chain1">链4</div>
          <div class="chainRight">
            <div class="identity">市级:</div>
            <div> <el-input type="number"
                        v-model="productRebateList.line4[0]"
                        size="mini"
                        :min="0"
                        label="描述文字"></el-input></div>

          </div>
        </div>
        <div class="rebate">
          <div class="identity">市级返居间:</div>
          <div> <el-input type="number"
                      v-model="productRebateList.intermediaryFeeList[0]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></div>
        </div>
        <div class="rebate">
          <span class="identity">县级返居间:</span>
          <span> <el-input type="number"
                      v-model="productRebateList.intermediaryFeeList[1]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></span>
        </div>
        <div class="rebate">
          <span class="identity">个人返居间:</span>
          <span> <el-input type="number"
                      v-model="productRebateList.intermediaryFeeList[2]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></span>
        </div>

        <div class="rebate">
          <span class="identity">特级返居间市级:</span>
          <span> <el-input type="number"
                      v-model="productRebateList.superIntermediaryFeeList[0]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></span>
        </div>
        <div class="rebate">
          <span class="identity">特级返居间县级:</span>
          <span> <el-input type="number"
                      v-model="productRebateList.superIntermediaryFeeList[1]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></span>
        </div>
        <div class="rebate">
          <span class="identity">特级返居间个人:</span>
          <span> <el-input type="number"
                      v-model="productRebateList.superIntermediaryFeeList[2]"
                      size="mini"
                      :min="0"
                      label="描述文字"></el-input></span>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="productRebateDialog = false">取 消</el-button>
          <el-button type="primary"
                     @click="modifyProductRebateInfo()">提 交</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
export default {
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      // 富文本编辑器的配置
      editorConfig: {},
      headers: {
        token: sessionStorage.getItem('token')
      },
      fileList: [],
      headFile: '',
      headFileList: '',
      codeFile: '',
      codeFileList: '',
      alterProductImages: false,
      ProductInfoImage: false,
      alterProduct: false,
      productRebateDialog: false,
      authAttr: '',
      productid: '',
      productId: '',
      pageNum: '',
      xqlist: {
        id: '',
        number: '',
        name: '',
        shortName: '',
        shortIntroduce: '',
        introduce: '',
        cover: '',
        List: '',
        images: '',
        price: '',
        type: '',
        createTime: '',
        updateTime: '',
        salesVolume: ''
      },
      //添加产品信息
      recompose: {
        shortName: '',
        name: '',
        shortIntroduce: '',
        status: '0',
        introduce: '',
        price: '',
        type: '',
        cover: null,
        images: null
      },
      productRebateList: {
        line1: [],
        line2: [],
        line3: [],
        line4: [],
        intermediaryFeeList: [],
        superIntermediaryFeeList: []
      },
      carouselList: []
    }
  },
  mounted() {
    this.getAuthAttr()
    this.pageNum = this.$route.query.pageNum
    if (this.$route.query.id != undefined) {
      window.sessionStorage.setItem('productid', this.$route.query.id)
      var id = window.sessionStorage.getItem('productid')
      this.productid = id
      this.updateHandler()
    } else {
      var id = window.sessionStorage.getItem('productid')
      this.productid = id
      this.updateHandler()
    }
    this.getProductRebateInfo()
  },

  methods: {
    //失效产品
    async delHandler(index) {
      const msg = await this.$confirm('你确定要失效该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/product/invalidProduct?id=${this.xqlist.id}`)
        if (res.code != 200) {
          return this.$message.error(res.msg)
        } else {
          this.back()
          return this.$message.success('失效产品成功')
        }
      }
    },
    // 修改封面和轮播图按钮
    updateImages() {
      this.productId = this.xqlist.id
      this.alterProductImages = true
    },
    // 打开修改产品介绍图片对话框
    OpenProductInfoImageFrame() {
      this.productId = this.xqlist.id
      this.ProductInfoImage = true
    },
    // 打开修改产品返利列表
    OpenProductRebate() {
      this.productRebateDialog = true
    },
    // 获取产品返利列表
    async getProductRebateInfo() {
      const { data: res } = await this.$http.get(`/admin/product/getProductRebateInfo?id=${this.productid}`)
      this.productRebateList = res.data
    },
    async modifyProductRebateInfo() {
      this.productRebateList.id = this.productid
      const { data: res } = await this.$http.post('/admin/product/modifyProductRebateInfo', this.productRebateList)
      if (res.code == 200) {
        this.productRebateDialog = false
        this.$message.success('修改产品返利成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-4')
      this.authAttr = authAttr
    },
    async updateContent() {
      this.alterProduct = true
      const { data: res } = await this.$http.get(`/admin/product/getProductInfoById?id=${this.xqlist.id}`)
      this.xqlist = res.data
    },
    async updateHandler() {
      const { data: res } = await this.$http.get(`/admin/product/getProductInfoById?id=${this.productid}`)
      //获取数据
      const { id, number, name, shortName, shortIntroduce, introduce, cover, List, images, price, type, createTime, updateTime, infoImageList, salesVolume } = res.data
      this.carouselList = []
      this.carouselList.push(this.$url + res.data.cover)
      this.xqlist = { ...this.xqlist, id, number, name, shortName, shortIntroduce, introduce, cover, List, images, price, type, createTime, updateTime, infoImageList, salesVolume }
      this.xqlist.introduce = this.xqlist.introduce.replace(/\<img /gi, '<img class="rich_pages" ')
      this.xqlist.infoImageList = []
      this.xqlist.images = []
      if (res.data.infoImageList != null && res.data.infoImageList != '') {
        res.data.infoImageList.forEach((e) => {
          this.xqlist.infoImageList.push(this.$url + e)
        })
      }
      if (res.data.images != null && res.data.images != '') {
        res.data.images.forEach((e) => {
          this.xqlist.images.push(this.$url + e)
        })
      }
    },
    // 修改信息提交
    async changexx() {
      var fileFormData = new FormData()
      fileFormData.append('name', this.xqlist.name)
      if (this.xqlist.shortName != null) {
        fileFormData.append('shortName', this.xqlist.shortName)
      }
      fileFormData.append('shortIntroduce', this.xqlist.shortIntroduce)
      fileFormData.append('price', this.xqlist.price)
      fileFormData.append('introduce', this.xqlist.introduce)
      fileFormData.append('type', this.xqlist.type)
      fileFormData.append('id', this.xqlist.id)
      fileFormData.append('salesVolume', this.xqlist.salesVolume)
      console.log(fileFormData)
      const { data: res } = await this.$http.post(`/admin/product/changeProductInfo`, fileFormData)
      this.alterProduct = false
      this.updateHandler()
    },
    // 修改封面和轮播图提交
    async updateArticleImages() {
      let fileFormData = new FormData()
      this.fileList.forEach((f) => {
        fileFormData.append('images', f.raw)
      })
      fileFormData.append('cover', this.cover)
      fileFormData.append('id', this.productId)
      const { data: res } = await this.$http.post(`/admin/product/uploadProductImage`, fileFormData)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.$refs.upload3.clearFiles()
        this.$refs.upload4.clearFiles()
        this.recompose = {}
        this.cover = ''
        this.fileList = []
        this.updateHandler()
        this.alterProductImages = false
      } else {
        this.$message.error(res.msg)
        this.$refs.upload3.clearFiles()
        this.$refs.upload4.clearFiles()
        this.cover = ''
        this.fileList = []
        this.alterProductImages = false
      }
    },
    // 修改产品介绍图片提交
    async uploadProductInfoImage() {
      let fileFormData = new FormData()
      this.fileList.forEach((f) => {
        fileFormData.append('images', f.raw)
      })
      fileFormData.append('id', this.productId)
      const { data: res } = await this.$http.post(`/admin/product/uploadProductInfoImage`, fileFormData)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.$refs.productImg.clearFiles()
        this.recompose = {}
        this.fileList = []
        this.updateHandler()
      } else {
        this.$message.error(res.msg)
        this.$refs.productImg.clearFiles()
        this.fileList = []
      }
      this.ProductInfoImage = false
    },

    hanlechange(file, fileList) {
      this.fileList = fileList
      this.fileList.forEach((f) => {
        this.formData.set(f.name, f.raw)
      })

      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { images, ...data } = this.rules
        this.rules = data
      }
      this.$refs.recompose.clearValidate('images')
      this.hideCode = fileList.length >= this.limitCode
    },
    handleChange(file, fileList) {
      this.cover = file.raw
      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { cover, ...data } = this.rules
        this.rules = data
      }
      this.$refs.recompose.clearValidate('cover')
      console.log(this.$refs.recompose.clearValidate())
      this.hideCode = fileList.length >= this.limitCode
    },
    handleRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.images = [{ required: true, message: '请上传图片文件', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleRemoves(file, fileList) {
      if (fileList.length == 0) {
        this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    // 返回
    back() {
      this.$router.push({ path: '/product', query: { pageNum: this.pageNum } })
    }
  }
}
</script>
<style lang='scss' scoped>
.chain {
  display: flex;
  margin-bottom: 15px;
  .chain1 {
    font-weight: 600;
    margin-right: 15px;
  }
  .chainRight {
    display: flex;
    margin-right: 10px;
    .identity {
      margin-right: 10px;
      font-weight: 600;
    }
  }
}
.rebate {
  display: flex;
  margin-bottom: 15px;
  .identity {
    margin-right: 15px;
    font-weight: 600;
  }
}
</style>